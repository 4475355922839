import { BASE_URL } from "../../utils/commonConstants";
import { postRequest, request } from "../../utils/request";
import { LOGIN_URL } from "../../utils/urlPath";
import jwt from 'jwt-decode';
import ConfigurationService from "./ConfigurationsService";

export const LOGIN_AUTH_TOKEN_KEY = "LOGIN_AUTH_TOEKEN"
export const LOGIN_REFRESH_TOKEN_KEY = "LOGIN_REFRESH_TOEKEN"
export const LAST_LOGIN_TIME_KEY = "LAST_LOGIN_TIME"
export const USER_DETAIL_KEY = "USER_DETAIL"

const _setUserInfo = (userInfo) => {
    window.userInfo = userInfo;
}

const _getUserInfo = () => {
    return window.userInfo;
}

const setToken = (accessToken, refreshToken) => {
    localStorage.setItem(LOGIN_AUTH_TOKEN_KEY, accessToken);
    localStorage.setItem(LOGIN_REFRESH_TOKEN_KEY, refreshToken);
    localStorage.setItem(LAST_LOGIN_TIME_KEY, new Date(Date.now()).getTime());
};
  
const getToken = () => {
    const lastLogin = localStorage.getItem(LAST_LOGIN_TIME_KEY);
    const loginAuth = localStorage.getItem(LOGIN_AUTH_TOKEN_KEY);
    if(lastLogin && loginAuth){
        // const now = new Date(Date.now()).getTime();
        // const timeAllowed = 1000 * 60 * 30;
        // const timeSinceLastLogin = now - lastLogin;
        // if (timeSinceLastLogin < timeAllowed) {
            // console.log("HERE")
            if(!_getUserInfo()){
                // console.log("IN")
                const decodedAccessToken = jwt(loginAuth);
                const data = { user_id: decodedAccessToken.user_id };
                const endpoint = `auth/get_user/`;
                postRequest(endpoint, data, (res) => {_setUserInfo(res)});
            }
            return loginAuth;
        // } else {
            // deleteToken();
            // return undefined;
        // }
    } else {
        deleteToken();
        return undefined;
    }
};

const deleteToken = () => {
    localStorage.removeItem(LAST_LOGIN_TIME_KEY);
    localStorage.removeItem(LOGIN_REFRESH_TOKEN_KEY);
    localStorage.removeItem(LOGIN_AUTH_TOKEN_KEY);
  }

const AuthService = {

    setUserInfo: (userInfo) => {
        _setUserInfo(userInfo);
    },
    
    getUserInfo: () => {
        return _getUserInfo();
    },

    loginUser: (email, password, callbackOnSuccess, callbackOnFailure) => {
        const requestBody = {
            username: email,
            password: password,
        };
        const sURL = `${BASE_URL}${LOGIN_URL}`;
        const options = {
            method: 'POST',
            body: JSON.stringify(requestBody),
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
            }
        };
        request(sURL, options)
            .then((response) => {
                console.log(response)
                if(response.access && response.refresh){
                    setToken(response.access, response.refresh);
                }
                callbackOnSuccess(response.access);
            })
            .catch((err) => {
                console.error(err)
                callbackOnFailure(err);
            });
    },

    logoutUser: () => {
        deleteToken();
        window.location.reload();
    },

    getUserDetails: function() {
        // sessionStorage.removeItem(USER_DETAIL_KEY);
        var userString = sessionStorage.getItem(USER_DETAIL_KEY);
        var user = undefined;
        if(!userString){
            user = {"firstName": "TEST", "lastName": "LASTTEST"}
            sessionStorage.setItem(USER_DETAIL_KEY, JSON.stringify(user));
        } else {
            user = JSON.parse(userString);
        }
        return user;
    },

    isLoggedIn: function() {
        // deleteToken();
        if(getToken()){
            ConfigurationService.initializeConfiguration();
            return true;
        }
        else
            return false;
    }
};

export default AuthService;