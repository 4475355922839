import { useNavigate } from 'react-router';
import styled from 'styled-components';

const HomeScreenWrapper = styled.div`
  display: flex;
  margin: 100px;
  flex-direction: column;
  justify-content: center;
  height: 200px;
  .home-screen-selection-button{
    // color: #405c43;
    font-size: 20px;
    font-weight: bold;
    margin-top: 20px;
  }
`

const HomeScreen = () => {
  const navigate = useNavigate();
  return (
    <HomeScreenWrapper className="home-screen-wrapper">
      <button onClick={() => {navigate("/sell")}} className="home-screen-selection-button">Sell Requests</button>
      <button onClick={() => {navigate("/resale")}} className="home-screen-selection-button">RaaS Requests</button>
    </HomeScreenWrapper>
  );
};

export default HomeScreen;
