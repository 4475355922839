import styled from 'styled-components';
import { useState } from 'react';
import { ReactComponent as DuplicateIcon } from "../../../static/tildi-duplicate-icon.svg";
import { ReactComponent as PlaceholderImage } from "../../../static/tildi-image-placeholder-icon.svg";
import { WAREHOUSE_ORDER_ITEM_STATUS_COMPLETED, WAREHOUSE_ORDER_ITEM_STATUS_REJECTED } from '../constants';
import { ReactComponent as DeleteIcon} from "../../../static/tildi-cross-icon.svg";
import PropTypes from 'prop-types';

const TableRow = styled.div`
  padding: 5px 10px;
  margin-top: 8px;
  max-width: 350px;
  display: flex;
  flex-direction: row;
  align-items: center;
  position:relative;
  border: 1px solid black;
  margin-bottom: 5px;
  background-color: #EDF9ED;
  .product-duplicate-button{
    position: absolute;
    bottom: 5px;
    right: 5px;
    svg{
      height: 20px;
    }
  }
  .table-cell-status{
    min-width: 100px;
    max-width: 100px;
    text-align: center;
    .status_text{
      padding: 4px;
      color: white;

      &.status_100{
        background-color: #C5C5C5;;
      }
      &.status_200{
        background-color: #04bb2d;
      }
      &.status_400{
        background-color: #b70000;
      }
      &.status_110{
        background-color: #e1c534;
      }
    }
  }
  .check-rejected-status-message{
    position: absolute;
    bottom: 10px;
    text-decoration: underline;
    font-size: 12px;
    text-align: center;
    width: 100px;
    // margin-top: 10px;
  }
  .table-cell-image{
    .product-image{
      max-height: 120px;
      max-width: 90px;
      border: 1px solid #e5e5e5;
      background-color: #e5e5e58a;
    }
  }
  .product-sku-data{
    top: 2px;
    right: 5px;
    position: absolute;
    font-size: 9px;
    margin: 5px;
    color: #A8B1A9;
  }
`
const TableCellDataWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`
const TableCellData = styled.div`
  display: flex;
  flex-direction: column;
  margin: 5px;
  text-align: left;
  min-width: 145px;
  max-width: 145px;

  .table-cell-data-title{
    color: #A8B1A9;
    font-size: 10px;
  }
  .table-cell-data{
    font-size: 13px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
`
const RejectionMessageDisplayDialog = styled.div`
display: ${props => props.display};    
position: absolute;
top: 0;
left: -12px;
background: #2e2e2e5c;
z-index: 9999;
height: 100%;
width: 96%;
padding: 20px;
.rejection-message-dialog{
  position: relative;
  height: 200px;
  background-color: white;
  padding: 50px;
  .rejection-dialog-close-button{
    position: absolute;
    top: 10px;
    right: 10px;
  }
}
`
const RejectItemIcon = styled.div`
  position: absolute;
  background-color: #cb0505;
  height: 20px;
  width: 20px;
  border-radius: 20px;
  border: 1px solid black;
  display: flex;
  align-items: center;
  justify-content: center;
  top: -10px;
  right: -10px;
  svg{
    height: 10px;
    width: 10px;
    path{
      fill: white;
      stroke: white;
    }
  }
`

function getValueValueListField(property, selectedPropertyValueId, dropDownValues) {
  var valueLabel = "";
  if (property.isMultiselect && selectedPropertyValueId && selectedPropertyValueId.length > 0) {
    selectedPropertyValueId.forEach((sSelectedId) => {
      const selectedDropDownValueDetail = dropDownValues.find((dropDownValueDetail) => dropDownValueDetail.id === sSelectedId);
      valueLabel += selectedDropDownValueDetail ? `, ${selectedDropDownValueDetail.label}` : " ,";
    });
    valueLabel = valueLabel.substring(1);
  } else {
    valueLabel = dropDownValues && selectedPropertyValueId && selectedPropertyValueId.length > 0 ?
      dropDownValues.find((categoryObjects) => { return selectedPropertyValueId === categoryObjects.id; }).label
      :
      "";
  }
  return valueLabel;
}

const WarehouseOrderItemRow = (props) => {
  const orderItem = props.orderItem;
  const commonProperties = props.commonProperties;
  const dropDownValues = props.dropDownValues;
  const statusConstants = props.statusConstants;
  const [rejectionMessageIdToShow, setRejectionMessageIdToShow] = useState("");

  const product = orderItem['request_item'];
  const data = [];
  var brandProperty = commonProperties.find((propertyObject) => propertyObject.id === 'brand');
  var sizeProperty = commonProperties.find((propertyObject) => propertyObject.id === 'size');
  var brandText = brandProperty && Object.keys(dropDownValues).length && getValueValueListField(brandProperty, orderItem['brand'], dropDownValues['brand']);
  var sizeText = sizeProperty && Object.keys(dropDownValues).length && getValueValueListField(sizeProperty, orderItem['size'], dropDownValues['size']);
  brandText = brandText ? brandText : (product.article ? product.article['brand'] : "");
  sizeText = sizeText ? sizeText : (product.article ? product.article['size_measure'] : "");

  data.push(
    <TableCellData key={`cell-data-product-name_${orderItem.id}-wrapper`}>
      <div key={`cell-data-title-product-name_${orderItem.id}`} className="table-cell-data-title">Name</div>
      <div key={`cell-data-product-name_${orderItem.id}`} className="table-cell-data cell-data-product-name">{orderItem['label'] ? orderItem['label'] : (product.article ? product.article['name'] : "")}</div>
    </TableCellData>
  )
  data.push(
    <TableCellData key={`cell-data-brand_${orderItem.id}-wrapper`}>
      <div key={`cell-data-title-brand_${orderItem.id}`} className="table-cell-data-title">Brand</div>
      <div key={`cell-data-brand_${orderItem.id}`} className="table-cell-data cell-data-brand">{brandText}</div>
    </TableCellData>
  )
  data.push(
    <TableCellData key={`cell-data-size_${orderItem.id}-wrapper`}>
      <div key={`cell-data-title-size_${orderItem.id}`} className="table-cell-data-title">Size</div>
      <div key={`cell-data-size_${orderItem.id}`} className="table-cell-data cell-data-size">{sizeText}</div>
    </TableCellData>
  )
  const getProductImageUrl = (orderItem) => {
    if (orderItem['image_front']) {
      return {
        url: orderItem['thumb_url'] ? orderItem['thumb_url'] : orderItem['image_front']
      }
    } else {
      return {
        url: (product['images_urls'][0] ? product['images_urls'][0] : undefined),
        altUrl: (product['images_urls'][0] ? product['images_urls'][0] : undefined)
      }
    }
  }
  var image = getProductImageUrl(orderItem).url
  return (
    <>
      <TableRow key={`${orderItem.id}-table-row`} className="table-row" onClick={() => { props.onClick(orderItem) }}>
        <div key={`${orderItem.id}-table-cell-image-wrapper`} className="table-cell-image">
          {
            image ?
              <img alt='' key={`${orderItem.id}-table-cell-image`} className="product-image" src={decodeURI(image)}></img>
              :
              <PlaceholderImage className="placeholder-icon" />
          }
        </div>
        <TableCellDataWrapper key={`${orderItem.id}-table-cell-data-wrapper`} className="table-cell-data-wrapper">{data}</TableCellDataWrapper>
        <div key={`${orderItem.id}-table-cell-status-wrapper`} className="table-cell-status">
          <div key={`${orderItem.id}-table-cell-status`} className={`status_text status_${orderItem.status}`}>{statusConstants ? statusConstants[orderItem.status] : ""}</div>
          {orderItem.status === WAREHOUSE_ORDER_ITEM_STATUS_REJECTED && 
            <div className="check-rejected-status-message" 
              onClick={(e) => { 
                window.scrollTo(0, 0); 
                setRejectionMessageIdToShow(orderItem.id); 
                e.stopPropagation();
              }}
            >
              View Rejection Message
            </div>
          }
        </div>
        <div className="product-sku-data">SKU: {orderItem.sku}</div>
        {orderItem.status !== WAREHOUSE_ORDER_ITEM_STATUS_REJECTED ?
          (
            <div
              className="product-duplicate-button"
              onClick={(e) => {
                props.onCloneItem(orderItem);
                e.stopPropagation();
              }}
            >
              <DuplicateIcon />
            </div>
          )
          :
          ""
        }
        { 
          orderItem.status !== WAREHOUSE_ORDER_ITEM_STATUS_REJECTED && 
          orderItem.status !== WAREHOUSE_ORDER_ITEM_STATUS_COMPLETED &&
          <RejectItemIcon>
            <DeleteIcon onClick={(e) => {
              e.stopPropagation();
              props.onRejectItem(orderItem)
            }}/>
          </RejectItemIcon>
        }
      </TableRow>
      {
        orderItem.status === WAREHOUSE_ORDER_ITEM_STATUS_REJECTED &&
        <RejectionMessageDisplayDialog key={`rejection-message-${orderItem.id}`} display={rejectionMessageIdToShow === orderItem.id ? 'block' : 'none'}>
          <div className="rejection-message-dialog">
            <div className="rejection-dialog-close-button" onClick={() => { setRejectionMessageIdToShow("") }}>Close</div>
            <h3>Rejection Message</h3>
            <div>{orderItem['rejection_message']}</div>
          </div>
        </RejectionMessageDisplayDialog>
      }
    </>
  );
}

WarehouseOrderItemRow.propTypes = {
  orderItem: PropTypes.object,
  commonProperties: PropTypes.array,
  dropDownValues: PropTypes.object,
  statusConstants: PropTypes.object,
  onRejectItem: PropTypes.func,
  onCloneItem: PropTypes.func,
  onClick: PropTypes.func,
}

export default WarehouseOrderItemRow;
