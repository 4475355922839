import { connect, useDispatch } from "react-redux";
import styled from 'styled-components';
import RaasOrderItemsListingScreenReducer, { initialState } from "./RaasOrderItemsListingScreenReducer";
import store from "../../store";
import { useEffect, useState } from "react";
import { cloneWarehouseItem, createBoxProducts, createNewRaasDataAvailableItem, createWarehouseCommonData, deleteRaasOrderItemBasic, fetchDropDownValues, fetchFieldValues, fetchOrder, fetchOrderItemStatusConstants, fetchOrderStatusConstants, fetchProperties, orderFetched, setOrderStatus, updateRaasOrderItemBasic, updateWarehouseCommonData } from "./RaasOrderItemsListingScreenActions";
import { useNavigate, useParams } from "react-router";
import LoadingOverlay from "../../common/components/LoadingOverlay";
import RaasOrderItemBasicListingScreen from "./components/RaaSOrderItemBasicListingScreen.js";
import OrderProductsListingScreen from "../../common/components/OrderProductsListingScreen.js";

const RaasOrderItemsListingScreenWrapper = styled.div``

const HOME_SCREEN_STORE_KEY = "HomeScreen";
const RAAS_ORDER_TYPE_DATA_AVAILABLE = 1;

const RaasOrderItemsListingScreen = (state) => {
  store.injectReducer(HOME_SCREEN_STORE_KEY, RaasOrderItemsListingScreenReducer);
  const params = useParams();
  const navigate = useNavigate();
  const orderId = params.id;
  const conditionsList = state.fieldValues ? state.fieldValues.condition : [];
  const segmentList = state.fieldValues ? state.fieldValues.segment : [];
  const orderItems = state.order ? state.order['order_items'] : undefined;
  const [isScreenLoading, setScreenLoading] = useState(false);
  const [dropDownValues, setDropDownValues] = useState({});
  const [commonProperties, setCommonProperties] = useState([]);
  const [orderStatusConstants, setOrderStatusConstants] = useState(undefined);
  const [statusConstants, setStatusConstants] = useState(undefined);
  const dispatch = useDispatch();
  var order = state.order;
  var orderType = order ? order.raas_order_type : "";
  // const [conditionsList, setConditionsList] = useState([]);

  const handleFailure = () => {
    setScreenLoading(false);
  }

  const onOrderLoad = (order) => {
    const onConditionsListLoaded = (fieldValues) => {
      setScreenLoading(false);
      dispatch(orderFetched(order, fieldValues));
    }
    fetchFieldValues(onConditionsListLoaded);
  };

  const initiateFetchOrder = () =>{
    if (!isScreenLoading)
      setScreenLoading(true);
    fetchOrder(orderId, onOrderLoad, handleFailure)
  }

  useEffect(() => {
      initiateFetchOrder();
      setScreenLoading(true);
      // initiateFetchOrder();
      fetchOrderItemStatusConstants((statusConstants) => {setStatusConstants(statusConstants)}, handleFailure);
      fetchDropDownValues((dropDownValues)=>{setDropDownValues(dropDownValues)}, handleFailure)
      fetchProperties((response) => {setCommonProperties(response)});
      fetchOrderStatusConstants((statusConstants)=>{setOrderStatusConstants(statusConstants)}, handleFailure);
  }, [])
  
  // console.log(order);
  return (
    <RaasOrderItemsListingScreenWrapper>
      <LoadingOverlay isLoading={isScreenLoading}/>
      {
        orderType === RAAS_ORDER_TYPE_DATA_AVAILABLE ? 
        <RaasOrderItemBasicListingScreen
          orderItems={orderItems}
          conditionsList={conditionsList}
          segmentList={segmentList}
          orderStatusConstants={orderStatusConstants}
          statusConstants={statusConstants}
          order={state.order}
          onClickBack={() => {navigate("/resale")}}
          createNew={(oCreateData, fSuccessCallBack, fFailureCallBack) => {
            setScreenLoading(true);
            createNewRaasDataAvailableItem(
              oCreateData, 
              (response) => {
                fSuccessCallBack(); 
                setScreenLoading(false); 
                initiateFetchOrder();
              },
              () => {
                if (fFailureCallBack){
                  fFailureCallBack();
                }
                handleFailure();
              }
            )
          }}
          onDelete={(productId) => {
            deleteRaasOrderItemBasic(productId, initiateFetchOrder);
          }}
          handleOrderComplete={(iOrderStatus) =>{
            setOrderStatus(
              state.order.id,
              iOrderStatus,
              () => {navigate(`/resale`)}
            );
          }}
          handleUpdateItem={(oUpdateData, fSuccessCallBack, fFailureCallback) => {
            setScreenLoading(true);
            updateRaasOrderItemBasic(oUpdateData, (response)=>{
              fSuccessCallBack();
              initiateFetchOrder();
            });
          }}
        /> 
        : 
        <OrderProductsListingScreen
          onClickBack={() => {navigate("/resale")}}
          orderItemStatusConstants={statusConstants}
          dropDownValues={dropDownValues}
          commonProperties={commonProperties}
          orderStatusConstants={orderStatusConstants}
          currentOrder={order}
          orderItems={order ? order.complete_order_items : []}
          handleCreateProduct={()=>{
            createBoxProducts(
              orderId, 
              (response)=>{
                initiateFetchOrder();
              }, 
              ()=>{
                handleFailure();
              }
            )
          }}
          handleOrderComplete={(sOrderId, iOrderStatus) =>{
            setOrderStatus(
              sOrderId,
              iOrderStatus,
              () => {navigate(`/resale`)}
            );
          }}
          handleCloneItem={(itemId) => {
            cloneWarehouseItem(itemId, (response) => {initiateFetchOrder()})
          }}
          handleCommonDataUpdate={(commonDataEditProperties, fSuccessCallBack) => {
            if(order.common_data){
              updateWarehouseCommonData(commonDataEditProperties, order.id, (response) => {fSuccessCallBack();initiateFetchOrder()});
            } else {
              createWarehouseCommonData(commonDataEditProperties, order.id, (response) => {fSuccessCallBack();initiateFetchOrder()});
            }
          }}
        />}
    </RaasOrderItemsListingScreenWrapper>
  );
};

const mapStateToProps = (state) => {
  const componentState = state[HOME_SCREEN_STORE_KEY];
  return componentState ? componentState : initialState;
}

export default connect(mapStateToProps)(RaasOrderItemsListingScreen);
