import "./App.css";
import store from "./store";
import MainScreen from "./screens/mainScreen/MainScreen";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import 'react-toastify/dist/ReactToastify.css';

function App() {
  return (
    <Provider store={store}>
      <BrowserRouter>
        <MainScreen />
      </BrowserRouter>
    </Provider>
  );
}

export default App;
