import { useEffect, useState, createRef } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import BottomRightOperationTools from '../../../common/components/BottomRightOperationTools';
import { ATTRIBUTE_TYPE_CURRENCY, ATTRIBUTE_TYPE_DROP_DOWN, ATTRIBUTE_TYPE_FLOAT, ATTRIBUTE_TYPE_INTEGER, ATTRIBUTE_TYPE_MEASUREMENT, ATTRIBUTE_TYPE_TEXT, ATTRIBUTE_TYPE_TEXT_AREA } from '../../../common/utils/commonConstants';
import CommonTextArea from '../../../common/components/CommonTextArea';
import CommonDropDownField from '../../../common/components/CommonDropDownField';
import CommonInputField from '../../../common/components/CommonTextField';
import Quagga from '@ericblade/quagga2';
import { toast } from "react-toastify";
import { ReactComponent as ScanBarcodeIcon} from "../../../static/tildi-scan-barcode.svg";
import { ReactComponent as CloseIcon} from "../../../static/tildi-cross-icon.svg";


const CreateNewOrderDialog = styled.div`

  padding: 25px;
  display: ${props => props.display};

  .create-new-field{
    display: flex;
    margin-top: 20px;
    flex-wrap: nowrap;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    .field-label{
      margin-right: 10px;
    }
  }
  .create-item-button{
    margin: 50px 0 0 0;
  }
  .close-icon{
    position: absolute;
    background-color: #cb0505;
    height: 30px;
    width: 30px;
    border-radius: 30px;
    border: 1px solid black;
    display: flex;
    align-items: center;
    justify-content: center;
    top: -10px;
    right: -10px;
    svg{
      height: 10px;
      width: 10px;
      path{
        fill: white;
        stroke: white;
      }
    }
  }
  .operation-button-wrapper{
    padding-right: 10px;
  }
  .property-field-misc-wrapper{
    position: relative;
  }
`

const ShowBarcodeScannerButton = styled.div`
  position: absolute;
  top: 15px;
  right: 10px;
  input[type="file"]{
    display: none;
  }
`

const getPropertyDisplayField = (dropDownValues, currentProduct, property, propertyRef, onChangeValue, focusOnNextField) => {
  var propertyValue = property.value;

    // useEffect(()=>{
    //   const enableCamera = async () => {
    //     alert("Enabling");
    //     console.log("enabling");
    //     await Quagga.CameraAccess.request(null, {});
    //   };
    //   const disableCamera = async () => {
    //     alert("Disabling");

    //     console.log("Disabling");
    //       await Quagga.CameraAccess.release();
    //   };
    //   const enumerateCameras = async () => {
    //     console.log("CAEMERA");

    //       const cameras = await Quagga.CameraAccess.enumerateVideoDevices();
    //       console.log('Cameras Detected: ', cameras);
    //       alert(JSON.stringify(cameras));
    //       return cameras;
    //   };
    //   if (navigator.mediaDevices && typeof navigator.mediaDevices.getUserMedia === 'function') {
    //     enableCamera()
    //       .then(disableCamera)
    //       .then(enumerateCameras)
    //       .then((cameras) => setCameras(cameras))
    //       .then(() => Quagga.CameraAccess.disableTorch()) // disable torch at start, in case it was enabled before and we hot-reloaded
    //       .catch((err) => { alert(JSON.stringify(err));console.log(err)});
    //     return () => disableCamera();
    //   } else {
    //     alert(navigator.mediaDevices);
    //   }
    // },[]);
  
  switch(property.type){
    case ATTRIBUTE_TYPE_TEXT:
      return (<CommonInputField
        innerRef={propertyRef}
        value={ propertyValue != undefined ? propertyValue : currentProduct[property.id] } 
        onChange={(response) => {onChangeValue(property.id, response.target.value)}} 
        onSubmit={() => {focusOnNextField(propertyRef)}}
        label={property.label + (property.isMandatory ? "*" : "")}
      />);
    
    case ATTRIBUTE_TYPE_DROP_DOWN:
      return (<CommonDropDownField
        id={property.id}
        innerRef={propertyRef}
        label={property.label + (property.isMandatory ? "*" : "")}
        onSelect={(id) => {onChangeValue(property.id, id);focusOnNextField(propertyRef)}}
        options={dropDownValues[property.id]}
        isMultiSelect={property.isMultiselect}
        canAddNew={property.canAdd}
        value={ propertyValue ? propertyValue : currentProduct[property.id] }
      />);

    case ATTRIBUTE_TYPE_TEXT_AREA:
      return (<CommonTextArea 
        value={ propertyValue != undefined ? propertyValue : currentProduct[property.id] } 
        onChange={(response) => {onChangeValue(property.id, response.target.value)}} 
        label={property.label + (property.isMandatory ? "*" : "")}
      />);
    case ATTRIBUTE_TYPE_FLOAT:
    case ATTRIBUTE_TYPE_INTEGER:
    case ATTRIBUTE_TYPE_CURRENCY:
    case ATTRIBUTE_TYPE_MEASUREMENT:
      return (<CommonInputField
        innerRef={propertyRef}
        value={ propertyValue != undefined ? propertyValue : currentProduct[property.id] } 
        onChange={(response) => {onChangeValue(property.id, response.target.value)}} 
        onSubmit={()=>{focusOnNextField(propertyRef)}}
        label={property.label + (property.isMandatory ? "*" : "")}
        suffix={property.type_suffix}
        variant='number'
      />);
    default:
      return (<div/>);
  }
}

const RaasItemBasicDetailScreen = (props) => {
  var propertiesToShow = props.propertiesToShow;
  var dropDownValues = props.dropDownValues;
  var fieldValues = props.fieldValues;
  const [currentFocusIndex, setCurrentFocusIndex] = useState(0);
  const [propertyRefs, setPropertyRefs] = useState(Array.from({ length: propertiesToShow.length }, () => createRef()));
  var isMandatoryFieldsFilled = true;
  useEffect(() => {
    propertyRefs[currentFocusIndex]?.current?.focus();
  }, [propertyRefs, currentFocusIndex]);
  for (var i = 0 ; i < propertiesToShow.length ; i++){
    var property = propertiesToShow[i]; 
    if (property.isMandatory && !fieldValues[property.id]){
      isMandatoryFieldsFilled = false;
      break;
    }
  }
  const focusOnNextField = (propertyRef) => {
    var index = propertyRefs.indexOf(propertyRef);
    if(index < propertyRefs.length - 1){
      setCurrentFocusIndex(index + 1);
    } else {
      document.activeElement.blur();
    }
  }
  return (<CreateNewOrderDialog display="block">
      {/* <div className="close-icon" onClick={props.onClose}><CloseIcon/></div> */}
      <h3 className="create-new-raas-title">{props.title}</h3>
      {propertiesToShow.map((property, index) => {
        property.value = fieldValues[property.id];
        var propertyField = getPropertyDisplayField(dropDownValues, fieldValues, property, propertyRefs[index], props.onChangeValue, focusOnNextField);
        if(property.showScanner){
          propertyField = (<div className="property-field-misc-wrapper">
            {propertyField}
            <ShowBarcodeScannerButton>
              <label htmlFor="image-upload" className="custom-file-upload">
                <ScanBarcodeIcon/>
                <input 
                  className="image-upload-input"
                  id="image-upload"
                  type="file" 
                  accept="image/*"
                  capture="environment"
                  onChange={
                    (e) => {
                      if(e.target.files[0]){
                        var reader = new FileReader();
                          reader.addEventListener("load",
                            () => {
                              var base64String = reader.result;
                              var config = {
                                decoder: {
                                  readers: [
                                    'code_128_reader',
                                    'ean_reader',
                                    'ean_5_reader',
                                    'ean_2_reader',
                                    'ean_8_reader',
                                    'code_39_reader',
                                    'code_39_vin_reader',
                                    'codabar_reader',
                                    'upc_reader',
                                    'upc_e_reader',
                                    'i2of5_reader',
                                    '2of5_reader',
                                    'code_93_reader',
                                    'code_32_reader',
                                  ]
                                },
                                src: base64String,
                                numOfWorkers: 0,
                                locate: false,
                              };
                              Quagga.decodeSingle(
                                config, 
                                (result) => {
                                  if(result.codeResult){
                                    props.onChangeValue(property.id, result.codeResult.code);
                                    focusOnNextField(propertyRefs[index]);
                                  } else {
                                    toast.info("EAN could not be read", {
                                      position: "bottom-center",
                                      autoClose: 5000,
                                      hideProgressBar: true,
                                      closeOnClick: true,
                                      pauseOnHover: true,
                                      draggable: true,
                                      progress: undefined,
                                      theme: "colored",
                                    })
                                  }
                                }
                              );
                            }  
                          );
                          if(e.target.files[0] != null){
                            reader.readAsDataURL(e.target.files[0]);
                          }
                      };
                    }
                  }
                />
              </label>
            </ShowBarcodeScannerButton>
          </div>);
        }
        return propertyField;
      })}
      <BottomRightOperationTools 
        showCompleteButton={isMandatoryFieldsFilled}
        onClickComplete={(e) => {
          props.onComplete();
          e.preventDefault();
        }}
        showCancelButton={true}
        onClickCancel={(e)=>{
          props.onClose();
          e.preventDefault();
        }}
      />
    </CreateNewOrderDialog>)
}

RaasItemBasicDetailScreen.propTypes = {
  title: PropTypes.string,
  propertiesToShow: PropTypes.array.isRequired,
  dropDownValues: PropTypes.object.isRequired,
  fieldValues: PropTypes.object.isRequired,
  onChangeValue: PropTypes.func.isRequired,
  onComplete: PropTypes.func.isRequired,
  onClose: PropTypes.func,
};

export default RaasItemBasicDetailScreen;