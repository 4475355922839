import { ORDERS_FETCHED } from "./SellOrdersListingScreenActionTypes";

export const initialState = {
  orders: [],
  statusConstants: undefined
};


const SellOrdersScreenReducer = (state = initialState, action) =>{
  switch (action.type) {
    
    case ORDERS_FETCHED:
      var newState = {...state}
      newState.orders = action.orders;
      newState.statusConstants = action.statusConstants;
      return newState;

    default:
      return state;
  }
};

export default SellOrdersScreenReducer;