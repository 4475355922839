import AuthService from "../../common/components/services/AuthService";
import { LOGIN_INPUT_CHANGE, LOGIN_USER } from "./LoginScreenActionTypes";

export const initialState = {
  email: "",
  password: "",
  // error: false
};


const LoginScreenReducer = (state = initialState, action) =>{
  switch (action.type) {
    case LOGIN_USER:
        AuthService.loginUser(action.email, action.password);
        return state

    case LOGIN_INPUT_CHANGE:
        var newState = {...state};
        newState[action.target] = action.value;
        return newState;
      
    default:
      return state;
  }
};

export default LoginScreenReducer;