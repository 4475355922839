import styled from 'styled-components';

const LoadingOverlayWrapper = styled.div`
  z-index: 9999;
  background-color: rgb(48 50 49 / 62%);
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0px;
  left: 0px;
  display: ${props => props.display};
  justify-content: center;
  overflow: hidden;
  border-radius: 0;
  div.loading-container{
    position: absolute;
    top:45vh;
    left: 45vw;
    animation: spinningColor 1.5s ease-in-out infinite;
    // margin: 50px auto;
    border: 5px double #f0eff5;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    // margin-top: 180px;
  }

  @keyframes spinningColor {
    0% {
      transform: rotate(360deg);
      border-top:5px dashed #fd9ce5;
      border-bottom:5px dashed #405c43;
    }
    25% {
      border-top:5px dashed #ffb1ec;
      border-bottom:5px dashed #517755;
    }
    50% {
      border-top:5px dashed #ffccf3;
      border-bottom:5px dashed #629467;
    }
    75% {
      border-top:5px dashed #ffb1ec;
      border-bottom:5px dashed #517755;
    }
    100% {
      border-top:5px dashed #fd9ce5;
      border-bottom:5px dashed #405c43;
    }
`

function LoadingOverlay(props){
  return (
    <LoadingOverlayWrapper className="photo-loading-overlay" display={props.isLoading ? "block" : "none"}>
        <div className="loading-container"/>
    </LoadingOverlayWrapper>
  );

}

export default LoadingOverlay;