import { useState } from 'react';
import styled from 'styled-components';
import { toast } from "react-toastify";

const WizardProductConditionWrapper = styled.div`
  position: absolute;
  top: 0;
  left: -4px;
  background: #f1f1f1eb;
  z-index: 9999;
  height: 100%;
  width: 92%;
  padding: 20px;
  div.product-condition-prompt{
    margin-top: 100px;
    margin-top: 100px;
    display: flex;
    flex-direction: column;
    align-items: center;

    textarea.rejection-messasge{
      margin-top: 20px;
    }

    button.product-condition-check{
      margin-top: 30px;
      background: gray;

      &.accept-condition {
        background: #00ad17;
      }
      &.reject-condition {
        background: #b70000;
      }
    }
  }
`

const WizardProductConditionStep = (props) => {
  const [isRejected, setRejection] = useState(props.isRejected);
  const currentProduct = props.currentProduct;
  var productConditionCheckPrompt =(<></>);
  const onRejection = (event) => {
    // console.log(event)
    //TODO: Maintain own state and don't pick from the DOM
    var rejectionMessage = event.target.previousSibling.value;
    if(rejectionMessage){
      props.setProductConditionApproval(currentProduct, true, event.target.previousSibling.value);
    } else {
      toast.error(
        "Please provide a rejection message",
        {
          theme: "colored",
        }
      );
    }
  };
  if(isRejected){
    productConditionCheckPrompt = (
      <div className="product-condition-prompt">
        <div className="condition-text">Please provide a rejection message:</div>
        <textarea className="rejection-messasge" name="rejection-message" cols="30  " rows="6" placeholder="Rejection Message" defaultValue={props.rejectionMessage}/>
        <button className="product-condition-check reject-condition" onClick={onRejection}>Reject</button>
        <button className="product-condition-check" onClick={props.onCancel}>Cancel</button>
      </div>
    );
  } else {
    productConditionCheckPrompt = (
      <div className="product-condition-prompt">
        <div className="condition-text">Is the product as described and fit for approval?</div>
        <button className="product-condition-check accept-condition" onClick={()=>{props.setProductConditionApproval(currentProduct, false)}}>Accept</button>
        <button className="product-condition-check reject-condition" onClick={()=>{setRejection(true)}}>Reject</button>
      </div>
    );
  }
  return (
    <WizardProductConditionWrapper className="wizard-product-condition-step-wrapper">
          <div className="product-detail"><b>Product SKU</b>: {currentProduct.sku}</div>
          {
            currentProduct.request_item && currentProduct.request_item.article ? 
              (<div className="product-detail"><b>Condition</b>: {currentProduct.request_item.article.condition}</div>) 
              : ""
          }
          {productConditionCheckPrompt}
    </WizardProductConditionWrapper>
  )
}

export default WizardProductConditionStep;