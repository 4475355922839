/* eslint-disable */
import React, { memo, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
// Styles
// import * as colors from '../../assets/tokens/colors';
// import * as shapes from '../../assets/tokens/shape-tokens';

const BaseTextfield = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  background: transparent;
  margin-bottom: 12px;
  min-height: 50px;
  border: 1px solid #b6cbb8;
  &.mini {
    height: 32px;
    margin-bottom: 0;
    margin-right: 8px;
  }
  &.disabled{
    cursor: not-allowed;
    background-color: rgba(235, 235, 235);
    box-shadow: none;
    border: 2px solid rgba(255, 255, 255, 0.15);
  }
`;

const BaseInput = styled.input`
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  outline: none;
  height: 100%;
  width: 80%;
  background: #fff;
  font-size: 18px;
  font-weight: 500;
  transition: all 0.1s ease-in;
  position: absolute;
  border: 0;
  left: 0;
  z-index: 2;
  padding-left: 12px;
  padding-top: 20px;
  box-sizing: border-box;
  &:focus {
    background: #fff;
    box-shadow: none;
  }
  &:hover {
    background: #fff;
    box-shadow: none;
  }
  &:focus ~ label {
    bottom: 28px;
    font-size: 11px;
  }
  &:disabled {
    cursor: not-allowed;
    background-color: rgba(235, 235, 235);
    box-shadow: none;
    border: 2px solid rgba(255, 255, 255, 0.15);
  }
  &.mini {
    padding-left: 6px !important;
    padding-top: 0 !important;
  }
  &.password {
  }
  &:focus-visible {
    outline: 0;
  }
`;

const BaseLabel = styled.label`
  color: rgba(0, 0, 0, 0.5);
  font-size: 18px;
  // font-weight: bold;
  position: absolute;
  bottom: 15px;
  left: 14px;
  z-index: 10;
  transition: all 0.1s ease-in;
  pointer-events: none;
`;

const VisibilityToggle = styled.button`
  position: absolute;
  right: 0;
  z-index: 3;
  background: transparent;
  border: none;
  outline: none;
  cursor: pointer;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0.2px;
  text-transform: uppercase;
  transition: all .1s ease-in;
  padding: 6px;
  border-radius: 2px;
  margin: 14px;
  &:hover {
  }
`;

const BaseSuffix = styled.div`
  position: absolute;
  top: 15px;
  right: 15px;
`

function CommonTextField(props) {
  let lastUpdate;
  const [state, setState] = useState({
    value: '',
    passwordVisible: false,
  });
  return (
    <BaseTextfield className={`${props.variant} ${props.disabled ? ' disabled' : ''}`}>
      {/* Input */}
      <BaseInput
        autoFocus={props.isAutoFocus}
        value={
          props.onChange ? (props.value != undefined ? props.value : '') : state.value
        }
        type={props.variant}
        ref={props.innerRef}
        className={props.variant}
        disabled={props.disabled}
        onChange={e => {
          const event = Object.assign({}, e);
          let value = event.target.value;
          if (props.onChange) {
            props.onChange({ target: { value } });
          }
          else setState({ value });
        }}
        onKeyDown={e => {
          const event = Object.assign({}, e);
          let value = event.target.value;
          if(props.onSubmit && e.key === 'Enter'){
            props.onSubmit({ target: { value } });
          } else if (props.onChange) {
            props.onChange({ target: { value } });
          } else setState({ value });
        }}
        enterKeyHint="Next"
      />
      {props.suffix && <BaseSuffix>{props.suffix}</BaseSuffix>}
      {/* Label */}
      <BaseLabel
        style={
          props.value || (props.defaultValue && props.defaultValue !== '')
            ? { top: '5px', fontSize: '11px' }
            : {}
        }
      >
        {props.label}
      </BaseLabel>
      {/* Visibility toggle */}
      {props.variant === 'password' && (
        <VisibilityToggle
          onClick={() => setState({ passwordVisible: !state.passwordVisible })}
        >
          {!state.passwordVisible ? 'anzeigen' : 'verstecken'}
        </VisibilityToggle>
      )}
      {props.onSave && (
        <VisibilityToggle
          onClick={props.onSave}
        >
          Speichern
        </VisibilityToggle>
      )}
    </BaseTextfield>
  );
}

CommonTextField.propTypes = {
  variant: PropTypes.string,
  value: PropTypes.any,
  defaultValue: PropTypes.string,
  innerRef: PropTypes.any,
  onChange: PropTypes.func,
  label: PropTypes.string,
  type: PropTypes.string,
  onSave: PropTypes.func,
  forceLowerCase: PropTypes.bool,
  disabled: PropTypes.bool,
  suffix: PropTypes.string,
  isAutoFocus: PropTypes.bool,
  onSubmit: PropTypes.func,
};

export default memo(CommonTextField);
