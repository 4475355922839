import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { ReactComponent as RemoveIcon } from "../../static/tildi-trash-white-icon.svg";


const Wrapper = styled.div`
  // max-height: 45px;
  width: 100%;
  flex-direction: column;
  display: flex;
  margin-bottom: 12px;
  // z-index: 999999;
  position: relative;
`;

const DropdownButton = styled.div`
  min-height: 50px;
  color: rgba(0, 0, 0, 0.5);
  padding: 0 12px;
  background: #fff;
  transition: all 0.1s ease-in;
  display: flex;
  justify-content: space-between;
  border: 1px solid #b6cbb8;
  font-size: 14px;
  overflow: hidden;
  flex-direction: column;
  label{
    position: absolute;
    transition: all .1s ease-in;
  }
  .dropdown-empty-value-icon{
    position: absolute;
    right: 15px;
    top: 15px;
    font-weight: bold;
    font-size: 20px;
  }
`;

const DropdownWrapper = styled.div`
  height: auto;
  display: ${props => props.display};
  width: 100%;
  flex-direction: column;
  border: 1px solid #b6cbb8;
  background: #fff;
  z-index: 999;
  height: auto;
  background: #fff;
  position: relative;

  input{
    border: 0;
    border-bottom: 1px solid #b6cbb8;
    border-radius: 0;
    font-size: 18px;
  }
`;

const AddNewOptionButton = styled.div`
  position: absolute;
  right: 8px;
  top: 8px;
  font-size: 15px;
  padding: 5px;
  min-width: 0;
  background-color: #9e849d;
  display: ${props => props.display};
  color: white;
  border-radius: 6px;
`

const DropdownOption = styled.div`
  min-height: 35px;
  padding: 4px 12px;
  cursor: pointer;
  transition: all 0.1s ease-in;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  // font-weight: bold;
  font-size: 18px;
  background: #fff;
  cursor: pointer;
  overflow: hidden;
  &:nth-child(odd){
    // background-color: #EDF9ED;
  }
  &:nth-child(even){
    background-color: #f3faf4;
  } 
`;

const DropDownSelection = styled.div`
  color: #405c43;
  font-weight: 500;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 20px 0 0 0;
`;

const DropDownSingleSelectValue = styled.div`
  font-size: 18px;
`

const DropDownMultiSelectValue = styled.div`
  color: rgb(64, 92, 67);
  background: white;
  margin: 0px 2px 3px 0px;
  padding: 2px 2px 2px 8px;
  border-radius: 15px;
  font-size: 16px;
  display: flex;
  border: 1px solid rgb(64, 92, 67);
  .drop-down-close-icon{
    width: 15px;
    height: 15px;
    padding: 2px 4px 2px 6px;
    path{
      fill: rgb(64, 92, 67);
    }
  }

`

function CommonDropDownField(props) {
  const [open, setOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const searchInputRef = useRef(null);
  const isMultiSelect = props.isMultiSelect;
  var selectedOption = isMultiSelect ? [] : undefined;
  var selectedIds = [];
  //Manage selected values and vislble options
  var selectionElement = [];
  var visibleOptions = props.options
  if (props.value) {
    if (isMultiSelect) {
      visibleOptions = [];
      const handleRemoveValue = (idToRemove) => {
        const newValue = selectedIds.filter((id) => id !== idToRemove);
        props.onSelect(newValue);
      }
      const createMultiselectedValueDOM = (option) => {
        return (
          <DropDownMultiSelectValue key={`selected-option-${option.id}`}>
            <span>{option.label}</span>
            <RemoveIcon
              className="drop-down-close-icon"
              onClick={(e) => {
                e.stopPropagation();
                handleRemoveValue(option.id);
              }}
            />
          </DropDownMultiSelectValue>
        )
      }
      for (var i = 0; i < props.options.length; i++) {
        var currentOption = props.options[i];
        if (props.value.includes(currentOption.id)) {
          selectedOption.push(currentOption);
          selectedIds.push(currentOption.id);
          selectionElement.push(createMultiselectedValueDOM(currentOption));
        } else {
          visibleOptions.push(currentOption);
        }
      }
    } else {
      selectedOption = props.options.find((option) => props.value === option.id);
      selectedIds.push(selectedOption.id);
      selectionElement.push(
        <DropDownSingleSelectValue key={`selected-option-${selectedOption.id}`}>
          <span>{selectedOption.label}</span>
        </DropDownSingleSelectValue>
      )
      // visibleOptions = props.options;
    }
  }
  if (searchTerm) {
    visibleOptions = visibleOptions.filter((option) => {
      return option.label.toLowerCase().includes(searchTerm.toLowerCase());
    });
  }

  useEffect(() => {
    const handleClickOutside = e => {
      const dropdownClicks = [...document.querySelectorAll(`.dropdown.${props.id}`)].map(
        element => element.contains(e.target),
      );
      if (!dropdownClicks.includes(true)) {
        setSearchTerm("");
        setOpen(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return function cleanup() {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (open) {
      searchInputRef.current.focus();
    }
  }, [open]);
  const isSelectedValuesEmpty = (props.value === undefined) || (Array.isArray(props.value) && props.value.length === 0);
  const visibleOptionElements = visibleOptions.map((option, idx) => (
    <DropdownOption
      key={`drop-down-option-${option.id}`}
      className='dropdown-value'
      onClick={() => {
        var newValue = undefined;
        if (isMultiSelect) {
          newValue = [...selectedIds]
          newValue.push(option.id);
        } else {
          newValue = option.id;
        }
        setOpen(false);
        setSearchTerm("");
        props.onSelect(newValue, option.id);
      }}
    >
      {option.label}
    </DropdownOption>
  ))
  var showEmptyIcon = props.canEmpty && !props.isMultiSelect && selectionElement.length > 0;
  return (
    <Wrapper
      // overflow={open ? 'visible' : 'hidden'}
      className={`dropdown ${props.id}`}
    >
      <DropdownButton
        tabIndex={Math.floor(500 + Math.random() * 999)}
        onFocus={props.disabled ? 
          () => { } : 
          (e) => { 
            if (
              e.target === document.activeElement || 
              (open && searchInputRef.current === document.activeElement)
            ) { 
              setOpen(!open); e.target.blur() 
            } 
          }
        }
        disabled={props.disabled}
        hasError={props.hasError}
        ref={props.innerRef}
      >
        <label key={`field-label-${props.label}`} style={isSelectedValuesEmpty ? { color: 'rgba(0, 0, 0, 0.5)', fontSize: '18px', top: '12px' } : { display: 'absolute', top: '2px', fontSize: '11px' }}>
          {props.label}
        </label>
        <DropDownSelection>{selectionElement}</DropDownSelection>
        {showEmptyIcon && <div onClick={(e) => { props.onSelect(props.isMultiSelect ? [] : null); e.stopPropagation() }} className="dropdown-empty-value-icon">x</div>}
      </DropdownButton>
      <DropdownWrapper
        display={open ? 'flex' : 'none'}>
        <input
          ref={searchInputRef}
          className="dropdown-search"
          placeholder={`Search ${props.label}`}
          onChange={(e) => { setSearchTerm(e.target.value) }}
          value={searchTerm}
        />
        <AddNewOptionButton
          display={props.canAddNew && searchTerm && visibleOptionElements.length === 0 ? 'block' : 'none'}
          className="create-new-option"
          onClick={() => { props.onAdd(searchTerm) }}>
          Add
        </AddNewOptionButton>
        {visibleOptionElements}
      </DropdownWrapper>
    </Wrapper>
  );
}

CommonDropDownField.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired,
  onSelect: PropTypes.func.isRequired,
  isMultiSelect: PropTypes.bool,
  values: PropTypes.array,
  withReset: PropTypes.bool,
  selectedIdx: PropTypes.number,
  selectedValue: PropTypes.any,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  hasError: PropTypes.bool,
  errorHint: PropTypes.string,
  controlled: PropTypes.bool,
  styles: PropTypes.object,
  canEmpty: PropTypes.bool,
  innerRef: PropTypes.any,
};

export default CommonDropDownField;
