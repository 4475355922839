

// export const BASE_URL =
//   process.env.NODE_ENV === 'production'
//     ? 'https://sell.tildi.shop:8001/'
//     : 'http://localhost:8000/';
 export const BASE_URL = `${window.location.protocol}//${window.location.hostname}:8001/`;
//export const BASE_URL = `${window.location.protocol}//${window.location.hostname}:8000/`;

export const API_URL = `${BASE_URL}api/v1/`;
export const toastifyConfig = {
  position: "bottom-center",
  autoClose: 5000,
  hideProgressBar: true,
  closeOnClick: true,
  pauseOnHover: false,
  draggable: false,
  progress: undefined,
  theme: "colored"
}

export const CUSTOMER_ORDER_TYPE = "CUSTOMER"
export const RESALE_ORDER_TYPE = "RESALE"

export const ATTRIBUTE_TYPE_TEXT = "text";
export const ATTRIBUTE_TYPE_FLOAT = "float";
export const ATTRIBUTE_TYPE_INTEGER = "integer";
export const ATTRIBUTE_TYPE_DROP_DOWN = "drop_down";
export const ATTRIBUTE_TYPE_TEXT_AREA = "text_area";
export const ATTRIBUTE_TYPE_CURRENCY = "currency";
export const ATTRIBUTE_TYPE_MEASUREMENT = "measurement";
