import AuthService from "../../common/components/services/AuthService";
import { USER_LOGIN_FAIL, USER_LOGIN_SUCCESS } from "../mainScreen/MainScreenActionTypes";
import { LOGIN_INPUT_CHANGE } from "./LoginScreenActionTypes";

const loginUser = (email, password, successCallback, failureCallback) => {
  console.log(email);
  return AuthService.loginUser(email, password, successCallback, failureCallback);
};

const loginSuccess = (token) =>{
  
  return {
    type: USER_LOGIN_SUCCESS,
    token: token,
    message: "Login Success"
  };
}

const loginFailed = (errorMessage) =>{
  
  return {
    type: USER_LOGIN_FAIL,
    message: errorMessage
  };
}

const loginInputChange = (key, value) => {
  return {
    type: LOGIN_INPUT_CHANGE,
    target: key,
    value: value
  };
}

export { loginUser, loginInputChange, loginSuccess, loginFailed };
