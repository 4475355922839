import { connect, useDispatch } from "react-redux";
import styled from 'styled-components';
import SellOrdersScreenReducer, { initialState } from "./RaasOrdersListingScreenReducer";
import store from "../../store";
import { useEffect, useState } from "react";
import { createNewOrder, fetchCustomers, fetchOrderTypes, fetchOrders, fetchStatusConstants, ordersFetched } from "./RaasOrdersListingScreenActions";
import { useNavigate } from "react-router";
import LoadingOverlay from "../../common/components/LoadingOverlay";
import { ReactComponent as BackArrow } from "../../static/tildi-double-left-arrow-icon.svg";
import BottomRightOperationTools from "../../common/components/BottomRightOperationTools";

const SellOrdersScreenWrapper = styled.div`
    margin: 20px 15px;
    text-align: center;
    position: relative;
    .home-screen-title{
        // text-align: center;
        font-weight: bold;
        font-size: 20px;
    }
    .home-screen-text{
      margin-top: 10px;
    }
    .home-screen-filters-container{
      min-height: 50px;
      font-size: 14px;
      color: grey;
      text-align: initial;
      margin-top: 15px;
      margin-bottom: 5px;
      padding: 10px 13px;
      background: #fff1fc;
      position: relative;
      border: 1px solid black;
      .filter-send-button{
        height: 25px;
        padding: 0 5px;
        background-color: grey;
        font-size: 15px;
        min-width: 0px;
        position: absolute;
        top: 6px;
        right: 10px;
      }
      .filter-reset-button{
        position: absolute;
        bottom: 6px;
        right: 10px;
        text-decoration: underline;
        font-size: 16px;
      }

      .home-scrren-filters-wrapper{
        margin-top: 5px;
        .filter-field-wrapper{
          display: flex;
          flex-direction: row;
          align-items: center;
          .filter-input{
            height: 20px;
            font-size: 15px;
            // margin: 0 10px;
            padding: 0 2px;
            margin-top: 5px;
          }
        }
      }
    }
`

const Table = styled.div`
    font-size: 13px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 20px;
`
const TableRow = styled.div`
  padding: 5px 10px 5px 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  border: 1px solid black;
  margin-bottom: 5px;
  // &:nth-child(odd){
    background-color: #EDF9ED;
  // }
  // &:nth-child(even){
    // background-color: #fcfdfd;
  // } 
  .table-cell-status{
    .status_text{
      padding: 4px 5px;
      color: white;
      text-align: center;
      max-width: 70px;
      width: 70px;
      &.status_100{
        background-color: #C5C5C5;
      }
      &.status_200{
        background-color: #04bb2d;
      }
      &.status_300{
        background-color: #e1c534;
      }
      &.status_400{
        background-color: #b70000;
      }
      &.status_110{
        background-color: #e1c534;
      }
    }
  }
`
const TableCellDataWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`
const TableCellData = styled.div`
  display: flex;
  flex-direction: column;
  margin: 5px;
  text-align: left;
  min-width: 110px;
  max-width: 110px;

  .table-cell-data-title{
    color: #A8B1A9;
    font-size: 10px;
  }
  .table-cell-data{
    font-size: 13px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
`
const CreateNewOrderDialogWrapper = styled.div`
  position: absolute;
  background-color: rgba(0, 0, 0, 0.35);
  top: -20px;
  height: 102%;
  width: 100vw;
  left: -15px;
  min-height: 700px;
  border-radius:0;
  overflow: hidden;
  display: ${props => props.display};
  .create-new-raas-dialog{
    position: absolute;
    top: 60px;
    width: 300px;
    // height: 300px;
    background-color: white;
    border: 1px solid black;
    left: 30px;
    padding: 25px;

    .create-new-field{
      display: flex;
      margin-top: 20px;
      flex-wrap: nowrap;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;

      .field-label{
        margin-right: 10px;
      }
    }

    .create-order-button{
      margin: 50px 0 0 0;
    }
  }
`
const BackButton = styled.div`
  display: flex;
  font-size: 12px;
  align-items: center;
  position: absolute;
  top: 0;
  padding: 3px 10px 3px 5px;
  background: #405c43;
  color: white;
  border-radius: 15px;
  font-weight: bold;
  .back-icon{
    height: 18px;
  }
`

const HOME_SCREEN_STORE_KEY = "HomeScreen";

const RaasOrdersListingScreen = (state) => {
  store.injectReducer(HOME_SCREEN_STORE_KEY, SellOrdersScreenReducer);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isScreenLoading, setScreenLoading] = useState(false);
  const [orderNumberFilter, setOrderNumberFilter] = useState("");
  const [showCreateNew, setShowCreateNew] = useState(false);
  const [customersList, setCustomersList] = useState([]);
  const [showButtonText, setShowButtonText] = useState(true);

  // console.log(customersList);
  const onOrdersLoad = (orders) => {
    const onLoadCustomersList = (customers) => {
      const onStatusConstantsLoaded = (statusConstants) => {
        const onOrderTypesLoaded = (orderTypes) => {
          setScreenLoading(false);
          dispatch(ordersFetched(orders, statusConstants, orderTypes));
        }
        fetchOrderTypes(onOrderTypesLoaded)
      }
      setCustomersList(customers)
      fetchStatusConstants(onStatusConstantsLoaded);
    }
    fetchCustomers(onLoadCustomersList);
  };

  const handleFailure = () => {
    setScreenLoading(false);
  }

  const initiateFetchOrders = () => {
    setScreenLoading(true);
    fetchOrders(orderNumberFilter, onOrdersLoad, handleFailure)
  }

  useEffect(() => {
    initiateFetchOrders();
  }, [])

  useEffect(() => {
    setShowButtonText(true);
    setTimeout(() => {
      setShowButtonText(false);
    }, 3000)
  }, [isScreenLoading])
  const onClickOfOrder = (orderId) => {
    navigate(`/resale/einkauf/${orderId}`);
  }
  if (state.orders) {
    var tableRows = state.orders.map((order) => {
      const data = [];
      data.push(
        <TableCellData key={`request-number-cell-${order.id}`}>
          <div key={`cell-data-title-order-number_${order.id}`} className="table-cell-data-title">Request Number</div>
          <div key={`cell-data-order-number_${order.id}`} className="table-cell-data cell-data-order-number">
            #{order['transaction_number']}
          </div>
        </TableCellData>
      )
      data.push(
        <TableCellData key={`return-number-cell-${order.id}`}>
          <div key={`cell-data-title-request_number_${order.id}`} className="table-cell-data-title">Return Number</div>
          <div key={`cell-data-request_number_${order.id}`} className="table-cell-data cell-data-order-type">
            {order['request_number']}
          </div>
        </TableCellData>
      )
      data.push(
        <TableCellData key={`request-date-cell-${order.id}`}>
          <div key={`cell-data-title-order-date_${order.id}`} className="table-cell-data-title">Request Date</div>
          <div key={`cell-data-order-date_${order.id}`} className="table-cell-data cell-data-order-date">
            {new Date(order['created']).toDateString()}
          </div>
        </TableCellData>
      )
      data.push(
        <TableCellData key={`customer-cell-${order.id}`}>
          <div key={`cell-data-title-order-customer_${order.id}`} className="table-cell-data-title">Customer</div>
          <div key={`cell-data-order-customer_${order.id}`} className="table-cell-data cell-data-order-type">
            {order.customer ? order.customer.label : ""}
          </div>
        </TableCellData>
      )
      return (
        <TableRow key={order.id} className="table-row" onClick={() => { onClickOfOrder(order.id) }}>
          <TableCellDataWrapper key={`${order.id}-table-cell-wrapper`} className="table-cell-data-wrapper">{data}</TableCellDataWrapper>
          <div key={`${order.id}-table-order-status`} className="table-cell-status">
            <div className={`status_text status_${order.status}`}>{state.statusConstants[order.status]}</div>
          </div>
        </TableRow>
      );

    });
  }

  const filterOrders = (forcedFilter) => {
    setScreenLoading(true);
    fetchOrders(forcedFilter != undefined ? forcedFilter : orderNumberFilter, onOrdersLoad, handleFailure)
  }
  // console.log(state.orderTypes)
  var orderTypesObj = [];
  for (var key in state.orderTypes) {
    var orderTypeLabel = state.orderTypes[key];
    orderTypesObj.push(<option key={`option-order-type-${key}`} value={key}>{orderTypeLabel}</option>);
  }
  return (
    <SellOrdersScreenWrapper>
      <LoadingOverlay isLoading={isScreenLoading} />
      <BackButton
        onClick={() => { navigate(`/`); }}
      >
        <BackArrow className="back-icon" />Main Menu
      </BackButton>
      <div className="home-screen-title">RaaS Requests</div>
      <div className="home-screen-text">Please create/select a request to work on.</div>
      <div className="home-screen-filters-container">
        <div>Filters:</div>
        <button className="filter-send-button" onClick={() => { filterOrders(); }}>Filter</button>
        <div
          className="filter-reset-button"
          onClick={() => {
            setOrderNumberFilter("");
            filterOrders("")
          }}
        >
          Reset
        </div>
        <div className="home-scrren-filters-wrapper">
          <div className="filter-field-wrapper">
            {/* <div className="filter-label">Order Number</div> */}
            <input
              placeholder="Request Number"
              className="filter-input"
              value={orderNumberFilter}
              onChange={(event) => { setOrderNumberFilter(event.target.value) }}
              onKeyUp={(event) => {
                if (event.key === 'Enter') {
                  filterOrders();
                }
              }}
            />
          </div>
        </div>
      </div>
      <Table className="table">
        {tableRows}
      </Table>
      {
        showCreateNew ?
          (
            <CreateNewOrderDialogWrapper
              className="create-new-dialog-wrapper"
              onClick={(e) => {
                e.stopPropagation();
                if (e.target.className.includes('create-new-dialog-wrapper')) {
                  setShowCreateNew(false);
                }
              }}
              display={showCreateNew ? 'block' : 'none'}
            >
              <div className="create-new-raas-dialog">
                <h3 className="create-new-raas-title">Create New Order</h3>
                <form>
                  <div className="create-new-field">
                    <label className="field-label" htmlFor="request-number">Return Number</label>
                    <input name="request-customer" id="request-number" type="text" placeholder="Return Number" />
                  </div>
                  <div className="create-new-field">
                    <label className="field-label" htmlFor="request-customer">Customer</label>
                    <select name="request-customer" id="request-customer" placeholder="Request Customer">
                      {customersList.map((customerObject) => {
                        return (
                          <option value={customerObject.id}>{customerObject.label}</option>
                        )
                      })}
                    </select>
                  </div>
                  <div className="create-new-field">
                    <label className="field-label" htmlFor="request-type">Order Type</label>
                    <select name="request-type" id="request-type" placeholder="Request Type">
                      {orderTypesObj}
                    </select>
                  </div>
                  <button className="create-order-button"
                    onClick={(e) => {
                      const formArray = e.target.form;
                      const requestNumber = formArray[0].value;
                      const requestCustomer = formArray[1].value;
                      const orderType = formArray[2].value;
                      console.log(requestNumber + " : " + requestCustomer)
                      setShowCreateNew(false);
                      createNewOrder({ "request_number": requestNumber, "customer": requestCustomer, "raas_order_type": orderType }, initiateFetchOrders)
                      e.preventDefault();
                    }}
                  >
                    Create
                  </button>
                </form>
              </div>
            </CreateNewOrderDialogWrapper>
          )
          :
          <BottomRightOperationTools
            showAddButton={true}
            showButtonText={showButtonText}
            onClickAdd={() => { window.scrollTo(0, 0); setShowCreateNew(true) }}
          />
      }
    </SellOrdersScreenWrapper>
  );
};

const mapStateToProps = (state) => {
  const componentState = state[HOME_SCREEN_STORE_KEY];
  return componentState ? componentState : initialState;
}

export default connect(mapStateToProps)(RaasOrdersListingScreen);
