import { useEffect, useState } from 'react';
import styled from 'styled-components';
import {getRequest, postRequest} from "../../../common/utils/request";
import { CREATE_WAREHOUSE_TRACKING, WAREHOUSE_ATTRIBUTES, WAREHOUSE_ATTRIBUTES_FETCH } from '../../../common/utils/urlPath';
import AuthService from '../../../common/components/services/AuthService';
import CommonTreeDropDownField from "../../../common/components/CommonTreeDropDownField";
import { useDispatch } from 'react-redux';
import BottomRightOperationTools from '../../../common/components/BottomRightOperationTools';

const WizardProductCategorizationWrapper = styled.div`
  .product-categorization-title{
    margin: 5px 0;
    font-weight: bold;
    font-size: 18px;
  }
  .product-categorization-drop-town-wrapper{
    margin-top: 10px;
  }
`

const NextButtonWrapper = styled.div`
  position: absolute;
  bottom: 5px;
  right: 5px;
  position:fixed;
  overflow-y:scroll;
  overflow-x:hidden;
  z-index: 999;
  display: ${props => props.display};
  .wizard-complete-next-button{
    background-color: green;
  }
`

const WizardProductCategorizationStep = (props) => {
  // console.log("\n\n\nTEST\n\n\n")
  const dispatch = useDispatch();
  const [startTime, setStartTime] = useState(Date.now());
  const currentOrder = props.currentOrder
  const currentProduct = props.currentProduct;
  const categoryValues = props.categoryValues;
  return (
    <WizardProductCategorizationWrapper 
      key="wizard-product-categorization-step"
    >
      <div className="product-detail"><b>Product SKU</b>: {currentProduct.sku}</div>
      <div className="product-categorization-title">Category</div>
      <div className="product-categorization-drop-town-wrapper">
        <CommonTreeDropDownField 
          id="category"
          label="Category"
          onSelect={(value) => {
            props.setProductCategoryState(value);
          }}
          options={categoryValues}
          isMultiselect={false}
          value={currentProduct.category}
        />
      </div>
      <BottomRightOperationTools
        showNextButton={currentProduct.category ? true : false}
        onClickNext={() => {
          if(!currentProduct.categorization_stage_completed){
            const trackingRequest = {
              "order_id": currentOrder.id,
              "item_id": currentProduct.id,
              "operation": "CATEGORISATION",
              "user": AuthService.getUserInfo().user.id,
              "time_consumed": (Date.now() - startTime)
            };
            postRequest(`${CREATE_WAREHOUSE_TRACKING}`, trackingRequest, (response) => {}, (response) => {});
          }
          var propertyValues = {};
          propertyValues.category = currentProduct.category;
          propertyValues.categorization_stage_completed = true;
          props.nextAfterCategorisation(propertyValues);
        }}
        showButtonText={true}
      />
    </WizardProductCategorizationWrapper>
  )
}

export default WizardProductCategorizationStep;