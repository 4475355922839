import { ORDER_FETCHED } from "./RaasOrderItemsListingScreenActionTypes";

export const initialState = {
  order: undefined,
  fieldValues: {}
};


const RaasOrderItemsListingScreenReducer = (state = initialState, action) =>{
  switch (action.type) {
    
    case ORDER_FETCHED:
      var newState = {...state}
      newState.order = action.order;
      newState.fieldValues = action.fieldValues;
      return newState;

    default:
      return state;
  }
};

export default RaasOrderItemsListingScreenReducer;