import { connect } from "react-redux";
import { Navigate, Route, Routes, useNavigate } from "react-router";
import LoginScreen from "../loginScreen/LoginScreen.js";
import SellOrdersListingScreen from "../SellOrdersListingScreen/SellOrdersListingScreen.js";
import logo from "../../static/tildi_logo.png";
import styled from 'styled-components';
import AuthService from "../../common/components/services/AuthService.js";
import { initialState } from "./MainScreenReducer.js";
import { ToastContainer } from 'react-toastify';
import SellOrderWizard from "../SellOrderWizard/SellOrderWizard.js";
import HomeScreen from "../homescreen/HomeScreen.js";
import RaasOrdersScreen from "../RassOrdersListingScreen/RaasOrdersListingScreen.js";
import RaasOrderItemsListingScreen from "../RassOrderItemsListingScreen/RaasOrderItemsListingScreen.js";
import SellOrderProductListingScreen from "../SellOrderProductListingScreen/SellOrderProductListingScreen.js";
import { CUSTOMER_ORDER_TYPE, RESALE_ORDER_TYPE } from "../../common/utils/commonConstants.js";
import {ReactComponent as HomeIcon} from "../../static/tildi-home-icon.svg";
import {ReactComponent as LogoutIcon} from "../../static/tildi-logout-icon.svg";
import { useEffect } from "react";


const PageWrapper = styled.div`
  @media (min-width: 800px) {
    width: 60%;
    margin: 0 auto;
  }
  // background-color: #eff7f0;
  background-color: white;
  min-height: 100vh;
  overflow: auto;
`

const Header = styled.div`
  border-bottom: 2px solid #6a796c;
  height: 55px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  border-radius: 0;

  .header-logo-wrapper{
    img.header-logo{
      height: 40px;
    }
    div.header-logo-warehouse-tagline{
      position: relative;
      top: -10px;
      left: 20px;
      font-style: italic;
      font-weight: bold;
      font-size: 8px;
    }
  }

  .util-icons-wrapper, .header-logo-wrapper{
    margin: 10px 20px 0px 20px;
  }

  .util-icons-wrapper{
    .util-icon{
      height: 25px;
      margin-left: 25px;
    }
  }
`

const Body = styled.div`
  min-height: calc(100vh - 100px);
`

//Handling of router for unauthenticated situations
const AuthRoute = ({ children }) => {
  const isLoggedIn = AuthService.isLoggedIn();

  return isLoggedIn ? children : <Navigate to="/login" />;
}

//Handling if already authentiated
const NonAuthRoute  = ({ children }) => {
  const isLoggedIn = AuthService.isLoggedIn();
  
  return isLoggedIn ? <Navigate to="/" /> : children;
}

export const MAIN_SCREEN_STORE_KEY = "MainScreen";

const MainScreen = (state) => {
  const navigate = useNavigate();

  const navigateToHome = () => {
    navigate("/");
  }

  const logoutUser = () => {
    AuthService.logoutUser();
  }

  return (
      <PageWrapper>
        <Header>
          <div className="header-logo-wrapper" onClick={navigateToHome} >
            <img className="header-logo" src={logo} alt='Tildi'/>
            <div className="header-logo-warehouse-tagline">Check-in</div>
          </div>
          {
            AuthService.isLoggedIn() ? 
            (
              <div className="util-icons-wrapper">
                {/* <img className="home-icon util-icon" onClick={navigateToHome} src={homeIcon} /> */}
                <HomeIcon className="home-icon util-icon" onClick={navigateToHome}/>
                {/* <img className="logout-icon util-icon" onClick={logoutUser} src={logoutIcon} /> */}
                <LogoutIcon className="logout-icon util-icon" onClick={logoutUser}/>
              </div>
            ) 
            : ""
          }
          
        </Header>
        <Body>
          <Routes>
            <Route path="/login" element={<NonAuthRoute><LoginScreen/></NonAuthRoute>}/>
            <Route path="/" element={<AuthRoute><HomeScreen/></AuthRoute>}/>
            <Route path="/sell" element={<AuthRoute><SellOrdersListingScreen/></AuthRoute>}/>
            <Route path="/sell/einkauf/:id" element={<AuthRoute><SellOrderProductListingScreen orderType={CUSTOMER_ORDER_TYPE}/></AuthRoute>}/>
            <Route path="/sell/einkauf/item/:id" element={<AuthRoute><SellOrderWizard orderType={CUSTOMER_ORDER_TYPE}/></AuthRoute>}/>
            <Route path="/resale" element={<AuthRoute><RaasOrdersScreen/></AuthRoute>}/>
            <Route path="/resale/einkauf/:id" element={<AuthRoute><RaasOrderItemsListingScreen orderType={RESALE_ORDER_TYPE}/></AuthRoute>}/>
            <Route path="/resale/einkauf/item/:id" element={<AuthRoute><SellOrderWizard orderType={RESALE_ORDER_TYPE}/></AuthRoute>}/>
            {/* <Route path="/resale/order/:id" element={<AuthRoute><MainWizardScreen/></AuthRoute>}/> */}
          </Routes>
        </Body>
 
        <ToastContainer 
          position="bottom-center"
          autoClose={5000}
          hideProgressBar
          newestOnTop={false}
          closeOnClick
          rtl={false}
          theme="light"
          />
        {/* <div className="footer">
          <div>Footer</div>
        </div> */}
      </PageWrapper>
  );
};

const mapStateToProps = (state) => {
  const componentState = state[MAIN_SCREEN_STORE_KEY];
  return componentState ? componentState : initialState;
}

export default connect(mapStateToProps)(MainScreen);

