export const WAREHOUSE_ORDER_GENERAL_URL = 'warehouseorders/';
export const FETCH_ORDERS_STATUS_CONSTANTS_URL = 'warehouseorders/status_constant/';
export const STATUS_CONSTANTS_URL = 'status_constant/';
export const LOGIN_URL = 'login/';
export const CREATE_BOX_PRODUCTS_URL = 'create-order-items/'
export const WAREHOUSE_ORDER_ITEMS_GENERAL_URL = 'warehouseorder-items/'
export const ORDER_ITEM_SET_IMAGE = 'set-image/'
export const ATTRIBUTE_VALUES_URL = 'attribute-values/';
export const CREATE_WAREHOUSE_TRACKING = 'warehouse-tracking/';
export const WAREHOUSE_ATTRIBUTES_FETCH = 'warehouse-attributes/fetch/';
export const WAREHOUSE_GENERATE_BARCODE_SUB_URL = 'generate-barcode/';
export const RAAS_ORDER_GENERAL_URL = 'raasorders/';
export const RAAS_ORDERS_STATUS_CONSTANTS_URL = `${RAAS_ORDER_GENERAL_URL}status_constant/`;
export const RAAS_CUSTOMERS_LIST_URL_PATH = 'customers-list/';
export const RAAS_ORDER_ITEM_BASIC_GENERAL_URL = `raasorder-items/`;
export const RAAS_ORDER_COMPLETE_ITEM_GENERAL_URL = `raasorder-complete-items/`;
export const RAAS_ORDER_ITEM_STATUS_CONSTANTS_URL = `${RAAS_ORDER_ITEM_BASIC_GENERAL_URL}status_constant/`;
export const RAAS_ORDER_TYPES_URL = `${RAAS_ORDER_GENERAL_URL}order_types/`;
export const WAREHOUSE_COMMON_DATA_URL = 'warehouseorder-common/';
export const RAAS_COMMON_DATA_URL = 'raasorder-common/';
export const WAREHOUSE_ITEM_CLONE_URL = `clone/`
export const RAAS_ITEM_CLONE_URL = `${RAAS_ORDER_ITEM_BASIC_GENERAL_URL}clone/`
export const WAREHOUSE_ITEM_BULK_REJECT_URL_PATH = `bulk-reject/`
export const CONFIGURATIONS_URL = 'common-configuration/get_configuration_by_type/';
