import { getRequest, patchRequest, postRequest, putRequest } from "../../common/utils/request";
import { ATTRIBUTE_VALUES_URL, CREATE_BOX_PRODUCTS_URL, FETCH_ORDERS_STATUS_CONSTANTS_URL, STATUS_CONSTANTS_URL, WAREHOUSE_ATTRIBUTES_FETCH, WAREHOUSE_COMMON_DATA_URL, WAREHOUSE_ITEM_BULK_REJECT_URL_PATH, WAREHOUSE_ITEM_CLONE_URL, WAREHOUSE_ORDER_GENERAL_URL, WAREHOUSE_ORDER_ITEMS_GENERAL_URL } from "../../common/utils/urlPath";

export const fetchOrder = (orderId, successCallback, fFaileruCallback) => {
    
    getRequest(`${WAREHOUSE_ORDER_GENERAL_URL}${orderId}/`, successCallback, fFaileruCallback);
}

export const fetchOrderItemStatusConstants = (successCallback, fFaileruCallback) => {
    
    getRequest(`${WAREHOUSE_ORDER_ITEMS_GENERAL_URL}${STATUS_CONSTANTS_URL}`, successCallback, fFaileruCallback);
}

export const fetchOrderStatusConstants = (successCallback) => {
    
    getRequest(FETCH_ORDERS_STATUS_CONSTANTS_URL, successCallback);
}

export const fetchDropDownValues = (successCallback, fFaileruCallback) => {
    
    getRequest(`${WAREHOUSE_ORDER_ITEMS_GENERAL_URL}${ATTRIBUTE_VALUES_URL}get/`, successCallback, fFaileruCallback);
}

export const createBoxProducts = (sOrderId, successCallback, fFaileruCallback) => {
    putRequest(`${WAREHOUSE_ORDER_ITEMS_GENERAL_URL}${CREATE_BOX_PRODUCTS_URL}${sOrderId}/`, {}, successCallback, fFaileruCallback);
}

export const setOrderStatus = (sOrderId, sOrderStatus, fSuccessCallback, fFaileruCallback) => {
    const requestBody = {
        pk: sOrderId,
        status: sOrderStatus
    }
    patchRequest(`${WAREHOUSE_ORDER_GENERAL_URL}save/${sOrderId}/?pk=${sOrderId}/`, requestBody, fSuccessCallback, fFaileruCallback);
}

export const fetchProperties = (onSuccess) => {
    getRequest(`${WAREHOUSE_ATTRIBUTES_FETCH}`, onSuccess);
}

export const updateWarehouseCommonData = (propertiesToUpdate, id, onSuccess) => {
    var properties = {...propertiesToUpdate};
    properties['common_data_for'] = id;
    patchRequest(`${WAREHOUSE_COMMON_DATA_URL}${id}/`, properties, onSuccess);
}

export const createWarehouseCommonData = (propertiesToUpdate, id, onSuccess) => {
    var properties = {...propertiesToUpdate};
    properties['common_data_for'] = id;
    postRequest(WAREHOUSE_COMMON_DATA_URL, properties, onSuccess);
}

export const cloneWarehouseItem = (itemId, onSuccess) => {
    postRequest(`${WAREHOUSE_ORDER_ITEMS_GENERAL_URL}${WAREHOUSE_ITEM_CLONE_URL}${itemId}/`, {}, onSuccess);
}

export const bulkRejectItems = (aItemIds, sRejectionMessage, onSuccess, onFailure) => {
    postRequest(
        `${WAREHOUSE_ORDER_ITEMS_GENERAL_URL}${WAREHOUSE_ITEM_BULK_REJECT_URL_PATH}`, 
        {'ids':aItemIds, 'reason':sRejectionMessage},
        onSuccess,
        onFailure
    );
}