import { USER_LOGIN_FAIL, USER_LOGIN_SUCCESS } from "./MainScreenActionTypes";

export const initialState = {
  loginAttempt : {
    loggedIn: false,
    currentToken: undefined,
    lastLoginStatus: undefined,
    lastLoginAttempt: undefined
  }
};


const MainScreenReducer = (state = initialState, action) =>{
  // console.log(action);
  switch (action.type) {

    case USER_LOGIN_SUCCESS:
      var newState = {...state}
      newState.loginAttempt = {
        loggedIn: true,
        currentToken: action.token,
        lastLoginStatus: "SUCCESS",
        lastLoginAttempt: new Date(),
        message: action.message
      }
      // console.log(newState);
      return newState;
      
    case USER_LOGIN_FAIL:
      var newState = {...state}
      newState.loginAttempt = {
        loggedIn: false,
        currentToken: undefined,
        lastLoginStatus: "FAILURE",
        lastLoginAttempt: new Date(),
        message: action.message
      }
      // console.log(newState);
      return newState;
      
      default:
        return state;
    }
};

export default MainScreenReducer;