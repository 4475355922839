import React from "react";
import { useDispatch, connect } from "react-redux";
import styled from 'styled-components';
import { loginFailed, loginInputChange, loginSuccess, loginUser } from "./LoginScreenActions";
import store from "../../store";
import LoginScreenReducer, { initialState } from "./LoginScreenReducer";
import { toast } from 'react-toastify';

const LoginWrapper = styled.div`
    margin: 40px;
    background-color: #80b88917;
    padding: 20px;
    .login-screen-title{
        text-align: center;
        font-weight: bold;
        font-size: 20px;
    }
    form.login-input-wrapper{
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        margin: 50px 0;
        input.login-input{
            min-width: 30%;
            margin: 20px 0;
        }
        button.login-button{
            margin: 20px 0;
        }
    }
`

const LOGIN_SCREEN_STORE_KEY = "LoginScreen";

const LoginScreen = (state) => {
    store.injectReducer(LOGIN_SCREEN_STORE_KEY, LoginScreenReducer);
    const dispatch = useDispatch();

    const redirectOnLoginSuccess = (auth_token) => {
        toast.success("Success", {
            position: "bottom-center",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          })
        dispatch(loginSuccess(auth_token));
    }

    const raiseErrorOnFailure = (err) => {
        toast.error(err.message, {
            position: "bottom-center",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          })
        dispatch(loginFailed(err));
    }


    const handleSubmit = (event) => {
        event.preventDefault();
        loginUser(state.email, state.password, redirectOnLoginSuccess, raiseErrorOnFailure);
  };


    const handleChange = (event) => {
        dispatch(loginInputChange(event.target.name, event.target.value));
    };

  return (
    <LoginWrapper>
        <div className="login-screen-title">Login</div>
        <form onSubmit={handleSubmit} className="login-input-wrapper">
            <input 
                name="email"
                type="email" 
                placeholder="E-Mail Address" 
                className="login-input login-input-username"
                value={state.email}
                onChange={handleChange}
            />
            <input 
                name="password"
                type="password" 
                placeholder="Password" 
                className="login-input login-input-password"
                value={state.password}
                onChange={handleChange}
            />
            <input className="login-button" type="submit" value="Login"/>
        </form>
    </LoginWrapper>
  );
};

const mapStateToProps = (state) => {
    // console.log(state[LOGIN_SCREEN_STORE_KEY]);
    const componentState = state[LOGIN_SCREEN_STORE_KEY];
    return componentState ? componentState : initialState;
}

export default connect(mapStateToProps)(LoginScreen);
