import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {ReactComponent as RightIcon} from '../../static/tildi-right-arrow-icon.svg'
import diacritics from 'diacritics';

const Wrapper = styled.div`
  // max-height: 45px;
  width: 100%;
  flex-direction: column;
  display: flex;
  margin-bottom: 12px;
  // z-index: 999999;
  position: relative;
`;

const DropdownButton = styled.div`
  min-height: 45px;
  color: rgba(0, 0, 0, 0.5);
  padding: 0 12px;
  background: #fff;
  transition: all 0.1s ease-in;
  display: flex;
  justify-content: space-between;
  border: 1px solid #b6cbb8;
  font-size: 14px;
  overflow: hidden;
  flex-direction: column;
  label{
    position: absolute;
    transition: all .1s ease-in;
  }
  .dropdown-empty-value-icon{
    position: absolute;
    right: 15px;
    top: 15px;
    font-weight: bold;
    font-size: 20px;
  }
`;

const DropdownWrapper = styled.div`
  height: auto;
  display: ${props => props.display};
  width: 100%;
  flex-direction: column;
  // display: flex;
  border: 1px solid #b6cbb8;
  background: #fff;
  z-index: 999;
  height: auto;
  background: #fff;

  input{
    border: 0;
    border-bottom: 1px solid #b6cbb8;
    border-radius: 0;
  }
`;

const DropdownOption = styled.div`
  position: relative;
  min-height: 50px;
  padding: 0 12px;
  cursor: pointer;
  transition: all 0.1s ease-in;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  // font-weight: bold;
  font-size: 18px;
  background: #fff;
  cursor: pointer;
  overflow: hidden;
  &:nth-child(odd){
    // background-color: #EDF9ED;
  }
  &:nth-child(even){
    background-color: #f5f5f5;
  } 
  .right-icon{
    border-radius: 20px;
    position: absolute;
    right: 8px;
    /* top: 20%; */
    height: 22px;
    background-color: #ffffff1f;
    border: 0.5px solid #adadad;
    padding: 5px;
  }
`;

const DropDownSelection = styled.div`
  color: #405c43;
  font-weight: 500;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 20px 0 10px 0;
  font-size: 18px;
`;

const DropDownSingleSelectValue = styled.div`
  display: flex;
`

const LevelDisplay = styled.div`
  margin-right: 5px;
  color: white;
  border-radius: 20px;
  height: 20px;
  width: 20px;
  text-align: center;
  padding: 2px;
  font-size: 16px;
  &.level-1{
    background-color: #a3caca;
  }
  &.level-2{
    background-color: #f7d38f;
  }
  &.level-3{
    background-color: #e6cbe7;
  }
`

function CommonTreeDropDownField(props) {
  const [open, setOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [treeList, setTreeList] = useState([]);
  const [currentTreeExpansionItem, setCurrentTreeExpansionItem] = useState({})
  const [baseList, setBaseList] = useState([]);
  const searchInputRef = useRef(null);
  const isMultiSelect = props.isMultiSelect;
  var selectedOption = isMultiSelect ? [] : undefined;
  var selectedIds = [];

  const toggleDropdownOpen = (isOpen) => {
    setSearchTerm("");
    setCurrentTreeExpansionItem({})
    setOpen(isOpen)
  }
  //Manage selected values and vislble options
  var selectionElement = [];
  var visibleOptions = [];
  if(props.value){
    selectedOption = props.options.find((option) => props.value === option.id);
    selectedIds.push(selectedOption.id);
    selectionElement.push(
      <DropDownSingleSelectValue key={`selected-option-${selectedOption.id}`}>
        <LevelDisplay className={`level-display level-${selectedOption.level}`}>{selectedOption.level}</LevelDisplay>
        <span>{selectedOption.label}</span>
      </DropDownSingleSelectValue>
    )
  }

  const handleClickOutside = e => {
    const dropdownClicks = [...document.querySelectorAll(`.dropdown.${props.id}`)].map(
      element => element.contains(e.target),
    );
    if (!dropdownClicks.includes(true)) {
      toggleDropdownOpen(false);
    }
  };

  useEffect(() => {
    var newBaseList = [];
    var newTreeList = [];
    var mapping = {};
    props.options.forEach((treeOption) => {
      var treeObject = {};
      if(mapping[treeOption.id]){
        treeObject = mapping[treeOption.id]
      } else {
        treeObject.id = treeOption.id;
        treeObject.children = [];
      }
      treeObject.label = treeOption.label;
      treeObject.level = treeOption.level;
      if(treeOption.parent){
        var parentTreeObject = mapping[treeOption.parent];
        if(!parentTreeObject){
          parentTreeObject = {
            id: treeOption.parent,
            children: []
          }
          mapping[treeObject.parent] = parentTreeObject;
        }
        parentTreeObject.children.push(treeObject);
      } else {
        newTreeList.push(treeObject);
      }
      mapping[treeObject.id] = treeObject;
      newBaseList.push(treeObject);
    });
    setTreeList(newTreeList);
    setBaseList(newBaseList);
    document.addEventListener('mousedown', handleClickOutside);
    return function cleanup() {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if(open){
      searchInputRef.current.focus();
    }
  }, [open]);

  const isSelectedValuesEmpty = (props.value === undefined) || (Array.isArray(props.value) && props.value.length === 0);

  if(searchTerm){
    visibleOptions = baseList.filter((option) => {
      return diacritics.remove(option.label.toLowerCase()).includes(diacritics.remove(searchTerm.toLowerCase()));
    }).sort((a,b) => {
      var levelDiff = b.level - a.level;
        if(levelDiff == 0){
          var bLengthDifference = b.length - searchTerm.length;
          var aLengthDifference = a.length - searchTerm.length;
          var lengthDifference = bLengthDifference - aLengthDifference;
          if(lengthDifference == 0){
            var aLabel = a.label;
            var bLabel = b.label;
            if(bLabel > aLabel){
              return -1;
            } else if(aLabel > bLabel) {
              return 1;
            } else {
              return 0;
            }
          } else {
            return lengthDifference;
          }
        } else {
          return levelDiff;
        }
    });
  } else {
    if(currentTreeExpansionItem.children){
      visibleOptions = currentTreeExpansionItem.children;
    } else {
      visibleOptions = treeList;
    }
  }
  var showEmptyIcon = props.canEmpty && !props.isMultiSelect && selectionElement.length > 0;
  return (
    <Wrapper
      // overflow={open ? 'visible' : 'hidden'}
      className={`dropdown ${props.id}`}
    >
      <DropdownButton
        onClick={props.disabled ? () => {} : () => {toggleDropdownOpen(!open);}}
        disabled={props.disabled}
        hasError={props.hasError}
      >
        <label key={`field-label-${props.label}`} style={isSelectedValuesEmpty ? {color: 'rgba(0, 0, 0, 0.5)', fontSize: '18px', top: '12px'} : {display: 'absolute', top: '2px', fontSize: '11px' }}>
          {props.label}
        </label>
        <DropDownSelection>{selectionElement}</DropDownSelection>
        {showEmptyIcon && <div onClick={(e) => {props.onSelect("");e.stopPropagation()}} className="dropdown-empty-value-icon">x</div>}
      </DropdownButton>
      <DropdownWrapper
        display={open ? 'flex' : 'none'}>
        <input 
          ref={searchInputRef}
          className="dropdown-search" 
          placeholder={`Search ${props.label}`} 
          onChange={(e)=>{setSearchTerm(e.target.value)}} 
          value={searchTerm}
        />
        {visibleOptions.map((option, idx) => (
            <DropdownOption 
              key={`drop-down-option-${option.id}`}
              className='dropdown-value'
              onClick={() => {
                var newValue = option.id;
                toggleDropdownOpen(false);
                props.onSelect(newValue, option.id);
              }}
            >
              <LevelDisplay className={`level-display level-${option.level}`}>{option.level}</LevelDisplay>
              {option.label}
              {option.children.length > 0 ? 
                <RightIcon
                  className="right-icon"
                  onClick={(e) => {
                    setCurrentTreeExpansionItem(option);
                    setSearchTerm("");
                    e.stopPropagation();
                  }}
                />
                : 
                ""}
            </DropdownOption>
          ))}
      </DropdownWrapper>
    </Wrapper>
  );
}

CommonTreeDropDownField.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired,
  onSelect: PropTypes.func.isRequired,
  isMultiSelect: PropTypes.bool,
  values: PropTypes.array,
  withReset: PropTypes.bool,
  selectedIdx: PropTypes.number,
  selectedValue: PropTypes.any,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  hasError: PropTypes.bool,
  errorHint: PropTypes.string,
  controlled: PropTypes.bool,
  styles: PropTypes.object,
  canEmpty: PropTypes.bool
};

export default CommonTreeDropDownField;
