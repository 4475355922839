import { deleteRequest, getRequest, patchRequest, postRequest, putRequest } from "../../common/utils/request";
import { ATTRIBUTE_VALUES_URL, CREATE_BOX_PRODUCTS_URL, RAAS_COMMON_DATA_URL, RAAS_ORDERS_STATUS_CONSTANTS_URL, RAAS_ORDER_COMPLETE_ITEM_GENERAL_URL, RAAS_ORDER_GENERAL_URL, RAAS_ORDER_ITEM_BASIC_GENERAL_URL, RAAS_ORDER_ITEM_STATUS_CONSTANTS_URL, STATUS_CONSTANTS_URL, WAREHOUSE_ATTRIBUTES_FETCH, WAREHOUSE_ITEM_CLONE_URL, WAREHOUSE_ORDER_ITEMS_GENERAL_URL } from "../../common/utils/urlPath";
import { ORDER_FETCHED } from "./RaasOrderItemsListingScreenActionTypes";


export const fetchOrder = (orderId, fSuccessCallback, handleFailure) => {
    
    getRequest(
        `${RAAS_ORDER_GENERAL_URL}${orderId}/`, 
        fSuccessCallback, 
        handleFailure
    );
}

export const createNewRaasDataAvailableItem = (oCreateData, fSuccessCallback, handleFailure) => {
    
    postRequest(
        `${RAAS_ORDER_ITEM_BASIC_GENERAL_URL}`,
        oCreateData,
        fSuccessCallback, 
        handleFailure
    );
}

export const orderFetched = (order, fieldValues) => {
    return {
        type: ORDER_FETCHED,
        order: order,
        fieldValues: fieldValues
    }
}

export const fetchStatusConstants = (successCallback) => {
    
    getRequest(RAAS_ORDER_ITEM_STATUS_CONSTANTS_URL, successCallback);
}

export const fetchFieldValues = (fSuccessCallback) =>{
    getRequest(`${WAREHOUSE_ORDER_ITEMS_GENERAL_URL}attribute-values/get/?fields=condition,segment`, fSuccessCallback);
}

export const fetchOrderItemStatusConstants = (successCallback, fFaileruCallback) => {
    
    getRequest(`${RAAS_ORDER_ITEM_BASIC_GENERAL_URL}${STATUS_CONSTANTS_URL}`, successCallback, fFaileruCallback);
}

export const fetchOrderStatusConstants = (successCallback) => {
    
    getRequest(RAAS_ORDERS_STATUS_CONSTANTS_URL, successCallback);
}

export const fetchDropDownValues = (successCallback, fFaileruCallback) => {
    
    getRequest(`${RAAS_ORDER_COMPLETE_ITEM_GENERAL_URL}${ATTRIBUTE_VALUES_URL}get/`, successCallback, fFaileruCallback);
}

export const createBoxProducts = (sOrderId, successCallback, fFaileruCallback) => {
    putRequest(`${RAAS_ORDER_COMPLETE_ITEM_GENERAL_URL}${CREATE_BOX_PRODUCTS_URL}${sOrderId}/`, {}, successCallback, fFaileruCallback);
}

export const setOrderStatus = (sOrderId, sOrderStatus, fSuccessCallback, fFaileruCallback) => {
    const requestBody = {
        pk: sOrderId,
        status: sOrderStatus
    }
    patchRequest(`${RAAS_ORDER_GENERAL_URL}save/${sOrderId}/?pk=${sOrderId}/`, requestBody, fSuccessCallback, fFaileruCallback);
}

export const fetchProperties = (onSuccess) => {
    getRequest(`${WAREHOUSE_ATTRIBUTES_FETCH}`, onSuccess);
}

export const updateWarehouseCommonData = (propertiesToUpdate, id, onSuccess) => {
    var properties = {...propertiesToUpdate};
    properties['common_data_for'] = id;
    patchRequest(`${RAAS_COMMON_DATA_URL}${id}/`, properties, onSuccess);
}

export const createWarehouseCommonData = (propertiesToUpdate, id, onSuccess) => {
    var properties = {...propertiesToUpdate};
    properties['common_data_for'] = id;
    postRequest(RAAS_COMMON_DATA_URL, properties, onSuccess);
}

export const cloneWarehouseItem = (itemId, onSuccess, onFailure) => {
    postRequest(`${RAAS_ORDER_COMPLETE_ITEM_GENERAL_URL}${WAREHOUSE_ITEM_CLONE_URL}${itemId}/`, {}, onSuccess, onFailure);
}

export const deleteRaasOrderItemBasic = (sItemId, onSuccess, onFailure) => {
    deleteRequest(`${RAAS_ORDER_ITEM_BASIC_GENERAL_URL}${sItemId}/`, {pk: sItemId}, onSuccess, onFailure);
}

export const updateRaasOrderItemBasic = (oItem, onSuccess, onFailure) => {
    patchRequest(`${RAAS_ORDER_ITEM_BASIC_GENERAL_URL}${oItem.id}/`, oItem, onSuccess, onFailure);
}