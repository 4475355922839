import { PRODUCT_SELECTION_STEP } from "./SellOrderWizard";
import { 
  WIZARD_DATA_INITIALIZED, SCREEN_CHANGED, ORDER_FETCHED_AND_STEP_FORWARD, 
  PRODUCT_SELECTED, NAVIGATE_WIZARD_STEP, SET_PRODUCT_CATEGORY_STATE, BACK_TO_PRODUCTS_SCREEN, DROP_DOWN_VALUES_SET
  } from "./SellOrderWizardActionTypes";


export const initialState = {
  currentOrder: undefined,
  currentProduct: undefined,
  statusConstants: undefined,
  currentScreen: undefined,
  currentScreenIndex: 0,
  selectedProduct: undefined,
  dropDownValues: {}
};


const SellOrderWizardReducer = (state = initialState, action) =>{
  switch (action.type) {

    case BACK_TO_PRODUCTS_SCREEN:
      var newState = {...state};
      newState.currentScreenIndex = 0;
      newState.currentScreen = PRODUCT_SELECTION_STEP
      newState.selectedProduct = undefined;
      return newState;

    case WIZARD_DATA_INITIALIZED:
      var newState = {...state};
      newState.currentProduct = action.product;
      newState.currentOrder = action.order;
      newState.statusConstants = action.statusConstants;
      newState.currentScreen = action.currentScreen;
      newState.currentScreenIndex = action.currentScreenIndex;
      newState.selectedProduct= action.product;
      newState.dropDownValues = action.dropDownValues;
      return newState;

    case DROP_DOWN_VALUES_SET:
      var newState = {...state};
      newState.dropDownValues = action.dropDownValues;
      return newState;

    case ORDER_FETCHED_AND_STEP_FORWARD:
      var newState = {...state}
      newState.currentScreen = action.currentScreen;
      newState.currentScreenIndex = action.currentScreenIndex;
      newState.currentOrder = action.order;
      newState.selectedProduct = action.selectedProduct;
    return newState;

    case PRODUCT_SELECTED:
      var newState = {...state}
      newState.selectedProduct = {...action.product};
      newState.currentScreen = action.currentScreen;
      newState.currentScreenIndex = action.currentScreenIndex;
      return newState;

    case NAVIGATE_WIZARD_STEP:
      var newState = {...state};
      newState.currentScreen = action.currentScreen;
      newState.currentScreenIndex = action.currentScreenIndex;
      return newState;

    case SET_PRODUCT_CATEGORY_STATE:
      var newState = {...state};
      var product = {...newState.selectedProduct} 
      product.category = action.productCategory;
      newState.selectedProduct = product;
      console.log(newState);
      return newState;

    default:
      return state;
  }
};

export default SellOrderWizardReducer;