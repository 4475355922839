import { connect, useDispatch } from "react-redux";
import styled from 'styled-components';
import store from "../../store";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router";
import { initializeData, navigateWizardStep, orderFetchedAndStepForward, RestActions, setDropDownValues, setProductCategoryState } from "./SellOrderWizardActions";
import { useParams } from "react-router";
import SellOrderWizardReducer, { initialState } from "./SellOrderWizardReducer";
// import WizardProductListingStep from "./components/WizardProductListingStep";
import WizardProductConditionStep from "./components/WizardProductConditionStep";
import WizardProductImageShootStep from "./components/WizardProductImageShootStep";
import WizardProductAttributesStep from "./components/WizardProductAttributesStep";
import LoadingOverlay from "../../common/components/LoadingOverlay";
import { postRequest } from "../../common/utils/request";
import { CREATE_WAREHOUSE_TRACKING } from "../../common/utils/urlPath";
import AuthService from "../../common/components/services/AuthService";
import WizardProductCategorizationStep from "./components/WizardProductCategorizationStep";
import { ReactComponent as DoubleLeftArrow} from "../../static/tildi-double-left-arrow-icon.svg";
import { ReactComponent as LeftArrow} from "../../static/tildi-left-arrow-icon.svg";
import { ReactComponent as RightArrow} from "../../static/tildi-right-arrow-icon.svg";
import { ReactComponent as PrintIcon} from "../../static/tildi-print-icon.svg";
import BottomRightOperationTools from "../../common/components/BottomRightOperationTools";
import { ReactComponent as BarcodeIcon} from "../../static/tildi-barcode-icon.svg";
import { RESALE_ORDER_TYPE } from "../../common/utils/commonConstants";

const WizardWrapper = styled.div`
  position:relative;
  margin: 20px 15px;
  // background-color: #80b88917;
  // padding: 10px;
  min-height: 500px;
  padding-bottom: 20px;
  .main-wizard-screen-title{
      font-weight: bold;
      font-size: 20px;
      text-align: center;
  }
  .main-wizard-screen-text{
    margin-top: 5px;
  }
  .reject-button-wrapper{
    display: flex;
    margin: 50px 0;
    justify-content: center;
    .reject-item-button{
      color: red;
      text-decoration: underline;
      font-size: 13px;
      width: 75px;
    }
  }
`

const WizardNavigator = styled.div`
  position: absolute;
  right: -5px;
  display: flex;
  top: 5px;
  align-items: center;
  height: 14px;
`

const WizardNavigatorItem = styled.div`
  font-size: 12px;
`

const WizardNavigatorControl = styled.div`
  text-align: center;
  font-size: 20px;
  // width: 10px;
  padding: 0 10px;
  min-width: 20px;
  svg{
    height: 18px;
    max-width: 20px;
  }
  &.disabled{
    svg{
      display: none;
    }
  }
`

const WizardProductCategorizationWrapper = styled.div`
  .product-categorization-title{
    margin: 5px 0;
    font-weight: bold;
    font-size: 18px;
  }
  .product-categorization-drop-town-wrapper{
    margin-top: 10px;
  }
`

const WizardBarcodeStepWrapper = styled.div`
  .product-barcode-body-wrapper{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 50px;
    .product-barcode-title{
      margin: 5px 0;
      font-weight: bold;
      font-size: 18px;
    }
    .generate-barcode-button{
      display: flex;
      margin-top: 30px;
      border: 1px solid darkgray;
      padding: 32px 0px;
      cursor: pointer;
      border-radius: 50px;
      align-items: center;
      justify-content: center;
      svg{
        height: 30px;
        width: 45px;
      }
    }
    .product-barcode-image-wrapper{
      position: relative;
      margin-top: 10px;
      .product-barcode-img{
          max-width: 350px;
      }
      .print-button-icon{
        position: absolute;
        top: -40px;
        right: 10px;
        height: 30px;
        background-color: #e5f0e6;
        padding: 6px 10px;
        border-radius: 30px;
        border: 0.5px solid #405c43;
      }
    }
  }
`

const NextButtonWrapper = styled.div`
  position: absolute;
  bottom: 5px;
  right: 5px;
  position:fixed;
  overflow-y:scroll;
  overflow-x:hidden;
  z-index: 999;
  display: ${props => props.display};
  .wizard-complete-next-button{
    background-color: green;
  }
`

const BackButton = styled.div`
  display: flex;
  font-size: 12px;
  align-items: center;
  position: absolute;
  top: 0;
  padding: 3px 10px 3px 5px;
  background: #405c43;
  color: white;
  border-radius: 15px;
  font-weight: bold;
  .back-icon{
    height: 18px;
  }
`

const EINZELANKAUF_TEXT = "Einzelankauf"
const BOXANKAUF_TEXT = "Boxankauf"
export const PRODUCT_SELECTION_STEP = "PRODUCT_SELECTION"
export const BOX_NUMBER_SELECTION_STEP = "BOX_NUMBER_SELECTION"
export const CONDITION_SELECTION_STEP = "CONDITION_SELECTION"
export const PHOTOSHOOT_STEP = "PHOTOSHOOT"
export const BARCODE_STEP = "BARCODE"
export const ATTRBUTE_FILLING_STEP = "ATTRBUTE_FILLING"
export const PRODUCT_CaTEGORIZATION_STEP = "PRODUCT_CaTEGORIZATION_STEP"

const REGULAR_FLOW = [ /*PRODUCT_SELECTION_STEP, */PRODUCT_CaTEGORIZATION_STEP, PHOTOSHOOT_STEP, ATTRBUTE_FILLING_STEP, BARCODE_STEP ]

const MAIN_WIZARD_SCREEN_STORE_KEY = "MainWizard";


/****************React Component*********************/
const SellOrderWizard = (state, props) => {
  const orderWizardType = state.orderType;
  store.injectReducer(MAIN_WIZARD_SCREEN_STORE_KEY, SellOrderWizardReducer);
  const navigate = useNavigate();
  const params = useParams();
  // const currentOrder = state.currentOrder;
  const currentProduct = state.selectedProduct;
  const currentOrder = currentProduct && currentProduct.order;
  // console.log(currentProduct)
  const orderId = params.id;
  const productId = params.id;
  const dispatch = useDispatch();
  const [isWizardLoading, setWizardLoading] = useState(false);
  const [isToReject, setToReject] = useState(false);
  const [startTime, setStartTime] = useState(Date.now());

  // useEffect(()=>{
  //   if(state.currentScreen == PRODUCT_CaTEGORIZATION_STEP){
  //     setStartTime(Date.now());
  //   }
  // },[state.currentScreen])
  /**
   * Initialize Wizard Data
   */
  useEffect(() => {
    setWizardLoading(true);
    const onProductLoaded = (product) => {

      const onStatusConstantsLoaded = (statusConstants) => {

        const onDropDownValuesLoaded = (dropDownValues) => {
          setWizardLoading(false);
          var iScreenIndex = product.inprogress_screen_index ? product.inprogress_screen_index : 0;
          var currentScreen = REGULAR_FLOW[iScreenIndex]
          dispatch(initializeData(product, statusConstants, dropDownValues, currentScreen, iScreenIndex));
        }

        RestActions[orderWizardType].fetchDropDownValues(onDropDownValuesLoaded, handleFailure)
      }
      
      RestActions[orderWizardType].fetchStatusConstants(onStatusConstantsLoaded, handleFailure);
    };
    
    // fetchOrder(orderId, onOrderLoaded, handleFailure)
    RestActions[orderWizardType].fetchProduct(productId, onProductLoaded, handleFailure)
  }, [])

  const handleFailure = () => {
    setWizardLoading(false);
  }

  /**
   * Handling of data changes in the Box number step
   */
  const handleChangeBoxAnkaufProductCount = (iNewCount) => {

    const onOrderLoaded = (order) => {
      const newIndex = state.currentScreenIndex;
      const newScreen = REGULAR_FLOW[newIndex];
      dispatch(orderFetchedAndStepForward(newScreen, newIndex, order));
    }

    const handleOnCreationOfBoxProducts = (response) => {
      // if(response.length == iNewCount){
        RestActions[orderWizardType].fetchOrder(orderId, onOrderLoaded)
      // }
    }
    RestActions[orderWizardType].createBoxProducts(iNewCount, currentOrder.id, handleOnCreationOfBoxProducts);
  }

  const exitProductWizard = () => {
    if(orderWizardType == RESALE_ORDER_TYPE){
      navigate(`/resale/einkauf/${currentOrder.id}`)
    } else {
      navigate(`/sell/einkauf/${currentOrder.id}`)
    }
  }

  const getNextScreenIndex = () => {
    var nextScreenIndex = state.currentScreenIndex + 1;
    if(nextScreenIndex >= REGULAR_FLOW.length) {
      nextScreenIndex = 0;
    }
    return nextScreenIndex
  }

  const onOrderLoadedAndOpenProductsListing = (order, nextPage, nextPageIndex) => {
    const updatedSelectedProduct = order.order_items.find((orderItem) => {return orderItem.id == currentProduct.id});
    dispatch(orderFetchedAndStepForward(nextPage, nextPageIndex, order, updatedSelectedProduct));
    setWizardLoading(false);
  }

  const loadOrder = (orderId, nextPage, nextPageIndex) => {
    setWizardLoading(true);
    RestActions[orderWizardType].fetchOrder(orderId, (order) => {onOrderLoadedAndOpenProductsListing(order, nextPage, nextPageIndex)}, handleFailure)
  }

  const loadOrderPostImageUpdate = (orderId, nextPage, nextPageIndex, propertyName, handleOnFailure) => {
    setWizardLoading(true);
    RestActions[orderWizardType].fetchOrder(
      orderId, 
      (order) => {
        const orderItemPostImage = order.order_items.find((orderItem) => {return orderItem.id == currentProduct.id})
        orderItemPostImage[propertyName] = orderItemPostImage[propertyName] + "?" + Date.now();
        onOrderLoadedAndOpenProductsListing(order, nextPage, nextPageIndex)
      },
      handleOnFailure ? handleOnFailure : handleFailure
    )
  }

  const setProductConditionApproval = (oProduct, isRejected, rejectionMessage) => {
    
    if(isRejected){
      setToReject(false);
      RestActions[orderWizardType].setProductStatus(
        oProduct.id, 
        400, 
        rejectionMessage, 
        undefined,
        exitProductWizard
      );
        // (response) => {loadOrder(state.currentOrder.id, PRODUCT_SELECTION_STEP, newIndex)});
    } else {
      const newIndex = state.currentScreenIndex + 1;
      const newScreen = REGULAR_FLOW[newIndex];
      RestActions[orderWizardType].setProductStatus(
        oProduct.id, 
        110, 
        undefined, 
        newIndex,
        (response) => {loadOrder(state.currentOrder.id, newScreen, newIndex)});
    }
  } 

  const onSelectionOfImage = (imageObj, isExistingImage, propertyName, shouldRemoveBackground, handleSuccess, handleOnFailure) => {
    RestActions[orderWizardType].setImage(
      state.selectedProduct.id, 
      imageObj.file, 
      imageObj.fileType, 
      isExistingImage, 
      propertyName, 
      shouldRemoveBackground,
      (response) => {if (handleSuccess){handleSuccess()}; loadOrderPostImageUpdate(state.currentOrder.id, state.currentScreen, state.currentScreenIndex, propertyName, handleOnFailure)},
      handleOnFailure
    );
  }

  const onCompletionOfImagePhotoShoot = () => {
    const newIndex = getNextScreenIndex();
    // const newIndex = state.currentScreenIndex + 1;
    const newScreen = REGULAR_FLOW[newIndex];
    // oPropertyValue['status'] = 200;
    RestActions[orderWizardType].updateNextStep(
      currentProduct.id, 
      newIndex,
      "photoshoot_stage_completed",
      (response) => {loadOrder(state.currentOrder.id, newScreen, newIndex)}
    );
  }

  const onProductAttributeComplete = (oPropertyValue) => {
    const newIndex = state.currentScreenIndex + 1;
    const newScreen = REGULAR_FLOW[newIndex];
    if(Object.keys(oPropertyValue).length){
      oPropertyValue['attribute_stage_completed'] = true;
      oPropertyValue['barcode_stage_completed'] = false;
      RestActions[orderWizardType].saveOrderItem(
        currentProduct.id, 
        oPropertyValue,
        newIndex,
        (response) => {loadOrder(state.currentOrder.id, newScreen, newIndex)}
      );
    } else {
      loadOrder(state.currentOrder.id, newScreen, newIndex)
    }
    
  }

  const nextAfterCategorisation = (oPropertyValue) => {
    const newIndex = state.currentScreenIndex + 1;
    const newScreen = REGULAR_FLOW[newIndex];
    // oPropertyValue['status'] = 200;
    oPropertyValue['attribute_stage_completed'] = false;
    oPropertyValue['barcode_stage_completed'] = false;
    RestActions[orderWizardType].saveOrderItem(
      currentProduct.id, 
      oPropertyValue,
      newIndex,
      (response) => {loadOrder(state.currentOrder.id, newScreen, newIndex)}
    );
  }

  var wizardStep = [(<div key="main-wizard-screen-title" className="main-wizard-screen-title">Order Details</div>)];

  const onCreateNewFieldValue = (field, value) => {
    setWizardLoading(true)
    RestActions[orderWizardType].addNewFieldValue(field, value, (response) => {
      if(response.is_manually_added){
        RestActions[orderWizardType].fetchDropDownValues((dropDownValues) => {setWizardLoading(false); dispatch(setDropDownValues(dropDownValues))}, handleFailure)
      } else {
        setWizardLoading(false);
      }
    })
  }

  const onSaveMiscImage = (miscImages) => {
    RestActions[orderWizardType].saveOrderItem(
      currentProduct.id, 
      {misc_images: miscImages},
      state.currentScreenIndex,
      (response) => {loadOrder(state.currentOrder.id, state.currentScreen, state.currentScreenIndex)}
    );
  }

  const onGenerateBarcode = (sProductId) => {
    const newIndex = state.currentScreenIndex;
    const newScreen = REGULAR_FLOW[newIndex];
    RestActions[orderWizardType].generateBarcode(sProductId, (response) => {loadOrder(state.currentOrder.id, newScreen, newIndex)});
  }

  if(state.currentScreen && currentOrder){  //Wizard Screens
    const requestDetails = currentOrder.request;
    const orderType = requestDetails ? requestDetails.is_box ? BOXANKAUF_TEXT : EINZELANKAUF_TEXT : "RaaS";
    const orderNumber = requestDetails ? requestDetails.transaction_number : currentOrder.transaction_number;
    wizardStep.push(<div key="main-wizard-screen-text" className="main-wizard-screen-text"><b>{orderType}:</b> {orderNumber}</div>);
    if(isToReject){
      window.scrollTo(0,0);
      wizardStep.push(
        <WizardProductConditionStep  
          key="wizard-products-condition"
          currentOrder={state.currentOrder}
          currentProduct={state.selectedProduct} 
          isRejected={true}
          rejectionMessage={state.selectedProduct.rejection_message}
          setProductConditionApproval={setProductConditionApproval}
          onCancel={() => {setToReject(false)}}
        />);
    } 
    // else {
      // console.log(state.currentScreen)
      switch(state.currentScreen){
  
        case PHOTOSHOOT_STEP:
          wizardStep.push(
            <WizardProductImageShootStep
              key="wizard-product-image-shoot-step"
              currentOrder={state.currentOrder}
              currentProduct={state.selectedProduct} 
              onSelectionOfImage={onSelectionOfImage}
              onSaveMiscImage={onSaveMiscImage}
              onCompletionOfImagePhotoShoot={onCompletionOfImagePhotoShoot}
              onClickSaveImage={(imageDetail) => {}}
            />
          );
          break;
  
        case ATTRBUTE_FILLING_STEP:
          wizardStep.push(
            <WizardProductAttributesStep
              key="wizard-product-attributes-step"
              currentOrder={state.currentOrder}
              currentProduct={state.selectedProduct}
              dropDownValues={state.dropDownValues}
              onCompleteStep={onProductAttributeComplete}
              onCreateNewValue={onCreateNewFieldValue}
            />
          )
          break;
        
        case PRODUCT_CaTEGORIZATION_STEP:
          wizardStep.push(
            <WizardProductCategorizationStep
              key="wizard-product-categorization-step"
              currentOrder = {currentOrder}
              currentProduct = {currentProduct}
              nextAfterCategorisation = { (propertyValues) => {nextAfterCategorisation(propertyValues)}}
              setProductCategoryState = { (value) => {dispatch(setProductCategoryState(value));}}
              categoryValues = {state.dropDownValues.category}
            />
          )
          break;

        case BARCODE_STEP:
          wizardStep.push(
            <WizardBarcodeStepWrapper 
              key="wizard-barcode-step"
            >
              <div className="product-detail"><b>Product SKU</b>: {currentProduct.sku}</div>
              <div className="product-barcode-body-wrapper">
                <div className="product-barcode-title">Barcode</div>
                {
                  currentProduct.barcode ? 
                  <div className="product-barcode-image-wrapper">
                    <img src={currentProduct.barcode} className="product-barcode-img"/>
                    <PrintIcon
                      className="print-button-icon" 
                      onClick={(e) => {
                        var w = window.open('', '_blank');
                        var printDocumentString = `<html><head><title>Product: ${currentProduct.sku}</title></head><body>` + 
                        `<img id="print-image-element" src="${currentProduct.barcode}"/>` + 
                        `<script>var img = document.getElementById("print-image-element");` + 
                        `img.addEventListener("load",function(){ window.focus();setTimeout(function () { window.print(); }, 500); window.onfocus = function () { setTimeout(function () { window.close(); }, 10000); } }); </script>` +
                        `</body></html>`;
                        w.document.write(printDocumentString);
                      }}
                    />
                  </div>
                  :""
                }
                {/* {currentProduct.barcode ? "" : <button onClick={() => {onGenerateBarcode(currentProduct.id);}}>Generate Barcode</button>} */}
                {currentProduct.barcode ? "" : <button className="generate-barcode-button" onClick={() => {onGenerateBarcode(currentProduct.id);}}><BarcodeIcon/></button>}
              </div>
              <BottomRightOperationTools
                showAddButton={false}
                showCompleteButton={true}
                showButtonText={true}
                onClickComplete={() => {
                  if(!currentProduct.categorization_stage_completed){
                    const trackingRequest = {
                      "order_id": currentOrder.id,
                      "item_id": currentProduct.id,
                      "operation": "BARCODE_COMPLETE",
                      "user": AuthService.getUserInfo().user.id,
                      "time_consumed": (Date.now() - startTime)
                    };
                    postRequest(`${CREATE_WAREHOUSE_TRACKING}`, trackingRequest, (response) => {}, (response) => {});
                  }
                  var propertyValues = {};
                  propertyValues.barcode_stage_completed = true;
                  propertyValues.status = 200;
                  RestActions[orderWizardType].saveOrderItem(currentProduct.id, propertyValues, state.currentScreenIndex, exitProductWizard);
                }}
              />
            </WizardBarcodeStepWrapper>
          );
          break;
      }
  }
    
  const isNavigationToProdductAttributesStepAllowed = currentProduct && 
    currentProduct.categorization_stage_completed &&
    currentProduct.photoshoot_stage_completed;
  const isNavigationToPhotoshootStepAllowed = currentProduct &&
    currentProduct.categorization_stage_completed;
  const isNavigationToBarcodetStepAllowed = currentProduct && 
    currentProduct.attribute_stage_completed && 
    currentProduct.categorization_stage_completed &&
    currentProduct.photoshoot_stage_completed;
  const canNavigateForward = () => {
    if((state.currentScreenIndex == 1 && isNavigationToProdductAttributesStepAllowed) ||
        (state.currentScreenIndex == 0 && isNavigationToPhotoshootStepAllowed) ||
        (state.currentScreenIndex == 2 && isNavigationToBarcodetStepAllowed)
      ){
      return true;
    } else {
      return false;
    }
  }
  const canNavigateBack = () => {
    if(state.currentScreenIndex == 0){
      return false;
    } else {
      return true;
    }
  }

  return (
    <WizardWrapper>
      <LoadingOverlay isLoading={isWizardLoading}/>
      <BackButton 
        onClick={exitProductWizard}
      >
        <DoubleLeftArrow className="back-icon"/>Products
      </BackButton>
      <WizardNavigator>
        <WizardNavigatorControl 
          onClick={() => {
            if(canNavigateBack()){
              const newIndex = state.currentScreenIndex - 1;
              const newScreen = REGULAR_FLOW[newIndex];
              dispatch(navigateWizardStep(newIndex, newScreen));
            }
          }}
          className={`${canNavigateBack() ? "" : "disabled"}`}
        >
          {/* <img src={backArrow}/> */}
          <LeftArrow/>
        </WizardNavigatorControl>
        <WizardNavigatorItem>
          {state.currentScreenIndex + 1} of {REGULAR_FLOW.length}
        </WizardNavigatorItem>
        <WizardNavigatorControl 
          onClick={() => {
            if(canNavigateForward()){
              const newIndex = state.currentScreenIndex + 1;
              const newScreen = REGULAR_FLOW[newIndex];
              dispatch(navigateWizardStep(newIndex, newScreen));
            }
          }}
          className={`${canNavigateForward() ? "" : "disabled"}`}
        >
          {/* <img src={nextArrow}/> */}
          <RightArrow/>
        </WizardNavigatorControl>
      </WizardNavigator>
      {wizardStep}
      {
        currentProduct && !isToReject ? 
        (<div className="reject-button-wrapper"><div onClick={() => {setToReject(true)}} className="reject-item-button">Reject Item</div></div>) : 
        ""
      }
    </WizardWrapper>
  );
};

const mapStateToProps = (state) => {
  const componentState = state[MAIN_WIZARD_SCREEN_STORE_KEY];
  return componentState ? componentState : initialState;
}

export default connect(mapStateToProps)(SellOrderWizard);
