import { getRequest} from "../../utils/request";
import { CONFIGURATIONS_URL } from "../../utils/urlPath";

export const ENABLE_BACKGROUND_REMOVAL = "ENABLE_BACKGROUND_REMOVAL";

const _setConfigurations = (configuration) => {
    window.checkinConfiguration = configuration;
}

const _setConfiguration = (key, value) => {
    if(!window.checkinConfiguration){
        window.checkinConfiguration = {};
    }
    window.checkinConfiguration[key] = value;
}

const _getConfiguration = (key) => {
    return window.checkinConfiguration[key];
}

const _getConfigurations = () => {
    return window.checkinConfiguration;
}

const _fetchConfiguration = async () => {
    var configurationResponse = await getRequest(`${CONFIGURATIONS_URL}?fields=${ENABLE_BACKGROUND_REMOVAL}`);
    var configurations = {}
    var filteredConfiguration = configurationResponse.filter((configurationObject)=>{return configurationObject.type == ENABLE_BACKGROUND_REMOVAL});
    configurations[ENABLE_BACKGROUND_REMOVAL] = Boolean(filteredConfiguration[0].value)
    _setConfigurations(configurations);
}

const ConfigurationsService = {

    getConfiguration: (key) => {
        if(!window.checkinConfiguration){
            _fetchConfiguration();
        } else {
            return _getConfiguration(key);
        }
        
    },

    initializeConfiguration: async () => {
        _fetchConfiguration();
    }

};

export default ConfigurationsService;