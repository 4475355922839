import { useEffect } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const ModalDialogOverlayWrapper = styled.div`
  z-index: 9900;
  background-color: rgb(48 50 49 / 62%);
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0px;
  left: 0px;
  // min-height: 100vh;
  display: ${props => props.display};
  justify-content: center;
  overflow: hidden;
`

function ModalDialogOverlay(props){
    useEffect(()=> {
      if(props.isShown){
        document.body.classList.add('modal-shown');
      } else {
        document.body.classList.remove('modal-shown');
      }
      return () => {
        document.body.classList.remove('modal-shown');
      };
    },[props.isShown]);
    return (
      <ModalDialogOverlayWrapper className="modal-dialog-overlay" display={props.isShown ? "flex" : "none"} onClick={props.onClose}>
        {props.children}
      </ModalDialogOverlayWrapper>
    );

}

ModalDialogOverlay.propTypes = {
  isShown: PropTypes.bool,
  onClose: PropTypes.func,
};

export default ModalDialogOverlay;