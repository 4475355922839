import styled from 'styled-components';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { ReactComponent as EditIcon} from "../../static/tildi-edit-icon.svg";
import { ReactComponent as DuplicateIcon} from "../../static/tildi-duplicate-icon.svg";
import { ReactComponent as DownExpandArrow} from "../../static/tildi-down-double-arrow-icon.svg";
import { ReactComponent as BackArrow} from "../../static/tildi-double-left-arrow-icon.svg";
import { ReactComponent as PlaceholderImage} from "../../static/tildi-image-placeholder-icon.svg";
import CommonTreeDropDownField from '../../common/components/CommonTreeDropDownField';
import CommonDropDownField from '../../common/components/CommonDropDownField';
import BottomRightOperationTools from '../../common/components/BottomRightOperationTools';
import { ATTRIBUTE_TYPE_DROP_DOWN } from '../../common/utils/commonConstants';



const OrderProductsListingScreenWrapper = styled.div`
  position:relative;
  margin: 20px 15px;
  // background-color: #80b88917;
  // padding: 10px;
  min-height: 500px;
  padding-bottom: 100px;
  .product-list-screen-title{
      font-weight: bold;
      font-size: 20px;
      text-align: center;
  }
  .product-list-screen-detail{
    margin-top: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    .status_text{
      display: flex;
      width: 80px;
      height: 15px;
      text-align: center;
      padding: 4px;
      color: white;
      margin-left: 20px;
      align-items: center;
      justify-content: center;
      font-size: 13px;

      &.status_100{
        background-color: #C5C5C5;;
      }
      &.status_200{
        background-color: #04bb2d;
      }
      &.status_400{
        background-color: #b70000;
      }
      &.status_300{
        background-color: #e1c534;
      }
    }
  }
  .product-list-screen-text{
    margin-top: 5px;
  }

  .order-common-data-container{
    min-height: 70px;
    font-size: 14px;
    color: grey;
    text-align: initial;
    margin-top: 15px;
    margin-bottom: 5px;
    padding: 10px 13px;
    background: #fdf8e2;
    position: relative;
    border: 1px solid black;

    .common-data-edit-icon{
      height: 18px;
      position: absolute;
      top: 10px;
      right: 5px;
    }

    .order-common-data-wrapper{
      display: flex;
      position: relative;
    }

    .order-common-data-edit-buttion{
      position: absolute;
      right: 10px;
      top: 10px;
    }

    .common-data-bottom-toolbar{
      display: flex;
      justify-content: center;
      .common-data-expand-icon{
        height: 12px;
        border-top: 1px solid #dadada;
        transition: all 0.2s ease-out;
        &.expanded{
          transform: rotate(180deg);
          border-bottom: 1px solid #dadada;
          border-top: none;
        }
      }
    }
  }

  .placeholder-icon{
    padding: 15px 15px;
    background-color: #ececec;
    border: 0.5px solid #6a6a6a;
    width: 50px;
    // height: 50px;
  }
`

const Table = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 20px;
`

const TableRow = styled.div`
  padding: 5px 10px 5px 10px;
  margin-top: 2px;
  max-width: 350px;
  display: flex;
  flex-direction: row;
  align-items: center;
  position:relative;
  border: 1px solid black;
  margin-bottom: 5px;
  background-color: #EDF9ED;
  .product-duplicate-button{
    position: absolute;
    bottom: 5px;
    right: 5px;
    svg{
      height: 20px;
    }
  }
  .table-cell-status{
    min-width: 100px;
    max-width: 100px;
    text-align: center;
    .status_text{
      padding: 4px;
      color: white;

      &.status_100{
        background-color: #C5C5C5;;
      }
      &.status_200{
        background-color: #04bb2d;
      }
      &.status_400{
        background-color: #b70000;
      }
      &.status_110{
        background-color: #e1c534;
      }
    }
  }
  .check-rejected-status-message{
    position: absolute;
    bottom: 10px;
    text-decoration: underline;
    font-size: 12px;
    text-align: center;
    width: 100px;
  }
  .table-cell-image{
    .product-image{
      max-height: 120px;
      max-width: 90px;
      border: 1px solid #e5e5e5;
      background-color: #e5e5e58a;
    }
  }
  .product-sku-data{
    top: 0px;
    right: 0px;
    position: absolute;
    font-size: 9px;
    margin: 5px;
    color: #A8B1A9;
  }
`

const CommonDataTableCellsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 10px 0;
  max-height: 80px;
  overflow: hidden;
  transition: max-height 0.5s ease-out;
  &.expanded{
    overflow-y: auto;
    max-height: 500px;
  }
  .table-cell-data-wrapper{
    background-color: #ffffffb8;
    position: relative;
    display: flex;
    border: 0.5px solid grey;
    border-radius: 25px;
    padding: 0 8px;
    max-width: 140px;
    margin: 3px 0;
    min-height: 35px;
  }
`

const TableCellDataWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`

const TableCellData = styled.div`
  display: flex;
  flex-direction: column;
  margin: 5px;
  text-align: left;
  min-width: 145px;
  max-width: 145px;

  .table-cell-data-title{
    color: #A8B1A9;
    font-size: 10px;
  }
  .table-cell-data{
    font-size: 13px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
`

const RejectionMessageDisplayDialog = styled.div`
  display: ${props => props.display};    
  position: absolute;
  top: 0;
  left: -12px;
  background: #2e2e2e5c;
  z-index: 9999;
  height: 100%;
  width: 96%;
  padding: 20px;
  .rejection-message-dialog{
    position: relative;
    height: 200px;
    background-color: white;
    padding: 50px;
    .rejection-dialog-close-button{
      position: absolute;
      top: 10px;
      right: 10px;
    }
  }
`

const BackButton = styled.div`
  display: flex;
  font-size: 12px;
  align-items: center;
  position: absolute;
  top: 0;
  padding: 3px 10px 3px 5px;
  background: #405c43;
  color: white;
  border-radius: 15px;
  font-weight: bold;
  .back-icon{
    height: 18px;
  }
`

const CommonDataEditWindow = styled.div`
  min-width: 97vw;
  min-height: 90vh;
  background-color: white;
  position: absolute;
  top: 0px;
  left: -5px;
  z-index: 999;
  padding-bottom: 100px;

  .common-data-window-title{
    font-weight: bold;
    font-size: 20px;
    text-align: center;
  }
  .common-data-properties-wrapper{
    width: 90%;
    margin: 20px auto;
    .common-data-property-field{
      max-height: 200px;
    }
  }
`

const CompletionConfirmationDialogWrapper = styled.div`
  min-width: 97vw;
  min-height: 90vh;
  height: 100%;
  width: 100%;
  background-color: #000000a1;
  position: absolute;
  top: 0px;
  left: -8px;
  z-index: 999;
  padding-bottom: 100px;
  .confirmation-dialog{
    position: relative;
    height: 200px;
    background: white;
    margin: 200px 20px;
    padding: 20px;
    .confirmation-title{
      font-weight: bold;
    }
    .cofirmation-dialog-buttons-section{
      position: absolute;
      right: 10px;
      bottom: 20px;
      button{
        &.yes-button{
          background-color: green;
        }
        margin: 10px;
      }
    }
  }
`
const EINZELANKAUF_TEXT = "Einzelankauf"
const BOXANKAUF_TEXT = "Boxankauf"

const getPropertyDisplayField = (property, propertyValue, values, onChange) => {
  switch(property.type){
    case ATTRIBUTE_TYPE_DROP_DOWN:
      return (
        <CommonDropDownField
          id={property.id}
          label={property.label + (property.isMandatory ? "*" : "")}
          onSelect={onChange}
          options={values}
          isMultiSelect={property.isMultiselect}
          canAddNew={property.canAdd}
          value={ propertyValue && propertyValue.length ? propertyValue : "" }
          onAdd={onChange}
          canEmpty={true}
          key={`common-property-drop-down-${property.id}`}
        />
      )

    default:
      return "";
  } 
}

function getValueValueListField(property, selectedPropertyValueId, dropDownValues) {
  var valueLabel = "";
  if (property.isMultiselect && selectedPropertyValueId && selectedPropertyValueId.length > 0) {
    selectedPropertyValueId.forEach((sSelectedId) => {
      const selectedDropDownValueDetail = dropDownValues.find((dropDownValueDetail) => dropDownValueDetail.id === sSelectedId);
      valueLabel += selectedDropDownValueDetail ? `, ${selectedDropDownValueDetail.label}` : " ,";
    });
    valueLabel = valueLabel.substring(1);
  } else {
    valueLabel = dropDownValues && selectedPropertyValueId && selectedPropertyValueId.length > 0 ?
    dropDownValues.find((categoryObjects) => { return selectedPropertyValueId === categoryObjects.id; }).label
      :
      "";
  }
  return valueLabel;
}

const OrderProductsListingScreen = (props) => {
    const navigate = useNavigate();
    const [rejectionMessageIdToShow, setRejectionMessageIdToShow] = useState("");
    const [showButtonText, setShowButtonText] = useState(true);
    const [isCommonDataExpanded, setCommonDataExpanded] = useState(false);
    const [isCommonDataEditWindowShown, setCommonDataEditWindowShown]  = useState(false);
    const [commonDataEditProperties, setCommonDataEditProperties] = useState({});
    const [isCompletionConfirmationDialogShown, setCompletionConfirmationDialogShown] = useState(false);
    const statusConstants=props.orderItemStatusConstants;
    const dropDownValues=props.dropDownValues;
    const commonProperties=props.commonProperties;
    const orderStatusConstants=props.orderStatusConstants;
    const currentOrder = props.currentOrder;
    var tableRows = [];
    var isAllProductsComplete = currentOrder && currentOrder['order_items'].length > 0;
    useEffect(() => {
      setShowButtonText(true);
      setTimeout(() => {
        setShowButtonText(false);
      }, 3000)
    }, [props.currentOrder])

    // console.log(commonProperties);
    const onProductSelect = (oProduct) => {
      navigate(`/resale/einkauf/item/${oProduct.id}`);
    }

    const showCommonDataEditWindow = (shouldShow) => {
      if(currentOrder && statusConstants && commonProperties.length > 0 && Object.keys(dropDownValues).length > 0){
        setCommonDataEditWindowShown(shouldShow);
      }
    }

    const setCommonDataValue = (propertyId, propertyValue) => {
      var newCommonEditProperties = {...commonDataEditProperties};
      newCommonEditProperties[propertyId] = propertyValue;
      setCommonDataEditProperties(newCommonEditProperties);
    }

    const closeCommonDataPropertiesWindow = () => {
      showCommonDataEditWindow(false);
      setCommonDataEditProperties({});
    }

    var orderType = "";
    var orderNumber = "";
    var statusText = orderStatusConstants && currentOrder ? orderStatusConstants[currentOrder.status] : ""; 
    if (currentOrder) {
      const requestDetails = currentOrder.request;
      orderType = requestDetails ? requestDetails.is_box ? BOXANKAUF_TEXT : EINZELANKAUF_TEXT : "RaaS";
      orderNumber = requestDetails ? requestDetails.transaction_number : currentOrder.transaction_number;
      tableRows.push(currentOrder['order_items'].map((orderItem, index) => {
        if(orderItem.status !== 200 && orderItem.status !== 400){
          isAllProductsComplete = false;
        }
        const product = orderItem['request_item'];
        const data = [];
        var brandProperty = commonProperties.find((propertyObject) => propertyObject.id === 'brand');
        var sizeProperty = commonProperties.find((propertyObject) => propertyObject.id === 'size');
        var brandText = brandProperty && Object.keys(dropDownValues).length && getValueValueListField(brandProperty, orderItem['brand'], dropDownValues['brand']);
        var sizeText = sizeProperty && Object.keys(dropDownValues).length && getValueValueListField(sizeProperty, orderItem['size'], dropDownValues['size']);
        brandText = brandText ? brandText : (product && product.article ? product.article['brand'] : "");
        sizeText = sizeText ? sizeText : (product && product.article ? product.article['size_measure'] : "");

        data.push(
          <TableCellData key={`cell-data-product-name_${orderItem.id}-wrapper`}>
            <div key={`cell-data-title-product-name_${orderItem.id}`} className="table-cell-data-title">Name</div>
            <div key={`cell-data-product-name_${orderItem.id}`} className="table-cell-data cell-data-product-name">{orderItem['label'] ? orderItem['label'] : (product && product.article ? product.article['name'] : "")}</div>
          </TableCellData>
        )
        data.push(
          <TableCellData key={`cell-data-brand_${orderItem.id}-wrapper`}>
            <div key={`cell-data-title-brand_${orderItem.id}`} className="table-cell-data-title">Brand</div>
            <div key={`cell-data-brand_${orderItem.id}`} className="table-cell-data cell-data-brand">{brandText}</div>
          </TableCellData>
        )
        data.push(
          <TableCellData key={`cell-data-size_${orderItem.id}-wrapper`}>
            <div key={`cell-data-title-size_${orderItem.id}`} className="table-cell-data-title">Size</div>
            <div key={`cell-data-size_${orderItem.id}`} className="table-cell-data cell-data-size">{sizeText}</div>
          </TableCellData>
        )
        const getProductImageUrl = (orderItem) => {
          if (orderItem['image_front']){
            return {
              url: orderItem['thumb_url'] ? orderItem['thumb_url'] : orderItem['image_front']
            }
          } else {
            return {
              url: (product && product['images_urls'][0] ? product['images_urls'][0] : undefined),
              altUrl : (product && product['images_urls'][0] ? product['images_urls'][0] : undefined)
            }
          }
        }
        var image = getProductImageUrl(orderItem).url
        return (
          <>
            <TableRow key={`${orderItem.id}-table-row`} className="table-row" onClick={()=>{onProductSelect(orderItem)}}>
              <div key={`${orderItem.id}-table-cell-image-wrapper`} className="table-cell-image">
                {
                  image ?
                  <img key={`${orderItem.id}-table-cell-image`} alt='' className="product-image" src={image}></img>
                  :
                  <PlaceholderImage className="placeholder-icon"/>
                }
              </div>
              <TableCellDataWrapper key={`${orderItem.id}-table-cell-data-wrapper`} className="table-cell-data-wrapper">{data}</TableCellDataWrapper>
              <div  key={`${orderItem.id}-table-cell-status-wrapper`} className="table-cell-status">
                <div key={`${orderItem.id}-table-cell-status`} className={`status_text status_${orderItem.status}`}>{statusConstants ? statusConstants[orderItem.status] : ""}</div>
                {orderItem.status === 400 ? <div className="check-rejected-status-message" onClick={(e) => {window.scrollTo(0, 0); setRejectionMessageIdToShow(orderItem.id); e.stopPropagation()}}>View Rejection Message</div>:""}
              </div>
              <div className="product-sku-data">SKU: {orderItem.sku}</div>
              { orderItem.status !== 400 ?
                (
                  <div 
                    className="product-duplicate-button" 
                    onClick={(e) => {
                        e.stopPropagation();
                        props.handleCloneItem(orderItem.id)
                    }}
                  >
                    <DuplicateIcon/>
                  </div>
                )
                :
                ""
              }
            </TableRow>
            {
              orderItem.status === 400 ? 
              <RejectionMessageDisplayDialog key={`rejection-message-${orderItem.id}`} display={rejectionMessageIdToShow === orderItem.id ? 'block' : 'none'}>
                <div className="rejection-message-dialog">
                  <div className="rejection-dialog-close-button" onClick={() => {setRejectionMessageIdToShow("")}}>Close</div>
                  <h3>Rejection Message</h3>
                  <div>{orderItem['rejection_message']}</div>
                </div>
              </RejectionMessageDisplayDialog>
              :""
            }
          </>
        );
      }));
    }

    const getCommonData = (commonData, isExpanded, dropDownValues) => {
      var selectedCategoryId = commonData && commonData['category'];
      var categoryLabel = commonData && dropDownValues['category'] && selectedCategoryId ? dropDownValues['category'].find((categoryObjects) => {return selectedCategoryId === categoryObjects.id}).label : "";
      return (
        <CommonDataTableCellsWrapper key="common-data-table-cells" onClick={()=>{showCommonDataEditWindow(true)}} className={`common-data table-cells-wrapper ${isExpanded ? "expanded" : ""}`}>
          <div className="table-cell-data-wrapper">
            <TableCellData>
              <div className="table-cell-data-title">Category</div>
              <div className="table-cell-data cell-data-brand">{categoryLabel}</div>
            </TableCellData>
            <div className="order-common-data-edit-buttion"></div>
          </div>
          {
            commonProperties.length > 0 ? 
            commonProperties.map((property) => {
              if (property.type === ATTRIBUTE_TYPE_DROP_DOWN){
                var selectedPropertyValueId = commonData && commonData[property.id];
                var valueLabel = getValueValueListField(property, selectedPropertyValueId, dropDownValues[property.id]);
                return (
                  <div key={`common-property-${property.id}`} className="table-cell-data-wrapper">
                    <TableCellData>
                      <div className="table-cell-data-title">{property.label}</div>
                      <div className="table-cell-data cell-data-brand">{valueLabel}</div>
                    </TableCellData>
                    <div className="order-common-data-edit-buttion"></div>
                  </div>
                );
              } else {
                return "";
              }
            })
            : 
            ""
          }
        </CommonDataTableCellsWrapper>
      )
    }
    var commonData = currentOrder && currentOrder.common_data;
    return (
        <OrderProductsListingScreenWrapper className="product-listing">
          {
            !isCommonDataEditWindowShown && 
            (<>
              <div className="product-list-screen-title">Request Details</div>
              <div className="product-list-screen-detail">
                <div className="product-list-screen-text"><b>{orderType}:</b> {orderNumber}</div>
                  <div className={`status_text ${currentOrder ? "status_" + currentOrder.status : ""}`}>{statusText}</div>
              </div>
              <div className="product-list-screen-text">
                <span>Please select the product you wish to work on.</span>
              </div>
              <BackButton 
                onClick={props.onClickBack}
              >
                <BackArrow className="back-icon"/>Requests
              </BackButton>
              <div className={`order-common-data-container`}>
                <div className="order-common-data-title">Common Details:</div>
                {
                  currentOrder && Object.keys(dropDownValues).length > 0 ? 
                  getCommonData(currentOrder.common_data, isCommonDataExpanded, dropDownValues) 
                  : 
                  ""
                }
                {/* <div className="order-common-data-edit-buttion"><EditIcon className="common-data-edit-icon"/></div> */}
                <div className="common-data-bottom-toolbar">
                  <DownExpandArrow 
                    className={`common-data-expand-icon ${isCommonDataExpanded ? "expanded" : ""}`} 
                    onClick={() => {setCommonDataExpanded(!isCommonDataExpanded)}}
                  />
                </div>
                <EditIcon className="common-data-edit-icon" onClick={() => {showCommonDataEditWindow(true)}}/>
              </div>
              <Table className="table">
                  {tableRows}
              </Table>
              <BottomRightOperationTools
                showButtonText={showButtonText}
                showCompleteButton={isAllProductsComplete}
                onClickComplete={()=>{setCompletionConfirmationDialogShown(true)}}
                showAddButton={true}
                onClickAdd={props.handleCreateProduct}
              />
            </>)
          }
          {isCompletionConfirmationDialogShown && (
            <CompletionConfirmationDialogWrapper>
              <div className="confirmation-dialog">
                <div className="confirmation-title">Are you sure you wish to Complete the Request?</div>
                <div className="cofirmation-dialog-buttons-section">
                  <button className="yes-button" onClick={() => {props.handleOrderComplete(currentOrder.id, 200)}}>Yes</button>
                  <button className="cancel-button" onClick={()=>{setCompletionConfirmationDialogShown(false)}}>Cancel</button>
                </div>
              </div>
            </CompletionConfirmationDialogWrapper>
          )}
          {isCommonDataEditWindowShown && (
            <CommonDataEditWindow>
              <BackButton onClick={closeCommonDataPropertiesWindow}>
                <BackArrow className="back-icon"/>Cancel
              </BackButton>
              <div className="common-data-window-title">Order Common Data</div>
              <div className="common-data-properties-wrapper">
                <CommonTreeDropDownField 
                  className="common-data-property-field"
                  id="category"
                  label="Category"
                  onSelect={(value) => {
                    // props.setProductCategoryState(value);
                    // console.log(value);
                    setCommonDataValue('category', value)
                  }}
                  options={Object.keys(dropDownValues).length ? dropDownValues.category : []}
                  isMultiselect={false}
                  value={commonDataEditProperties['category'] !== undefined ? commonDataEditProperties['category'] : commonData && commonData['category']}
                  canEmpty={true}
                />
                {
                  commonProperties.map((commonProperty) => {
                    var commonDataInOrder = commonData && commonData[commonProperty.id];
                    var isEdited = commonProperty.id in commonDataEditProperties;
                    var editedData = commonDataEditProperties[commonProperty.id];
                    return getPropertyDisplayField(
                      commonProperty, 
                      isEdited ? editedData : commonDataInOrder, 
                      dropDownValues[commonProperty.id], 
                      (value) => {
                        setCommonDataValue(commonProperty.id, value)
                      }
                    );
                  })
                }
              </div>
              <BottomRightOperationTools
                showAddButton={false}
                showCompleteButton={true}
                onClickComplete={() => {
                  const postProcessing = () => {
                    setCommonDataEditProperties({});
                    closeCommonDataPropertiesWindow();
                  }
                  props.handleCommonDataUpdate(commonDataEditProperties, postProcessing);
                }}
              />
            </CommonDataEditWindow>
          )}
        </OrderProductsListingScreenWrapper>
    )
}

export default OrderProductsListingScreen;
